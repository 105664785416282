import './Conversion.css';
import { useEffect, useState,useContext } from 'react';
import {Context} from '../Context';
import LoadingSpinner from '../Components/LoadingSpinner';
import ConversionService from '../Services/ConversionService';

const selectionOptions = [
    {text:'Cost Codes', value:'cost'},
    {text:'General Ledger String', value:'gl'},
];

const codeDirectionOptions = [
    {text:'Old -> New', value:'old'},
    {text:'New -> Old', value:'new'},
];

const SelectInputField = ({title, value, setTo, locked=false, options}) => {
    return(
        <div className='Project-Booking-Input-Field'>          
            <div className='Project-Booking-Input-Field-Label'>
                {title}:
            </div>
            {   locked &&

            <select onChange={e => setTo(e.target.value)} value={value} disabled>
                <option value="" disabled hidden>Select an option</option>
                {   
                options.map((element, key) => (
                    <option key={key} value={element.value}>{element.text}</option>
                ))
                }
            </select>

            }
            {   !locked &&

            <select onChange={e => setTo(e.target.value)} value={value}>
                <option value="" disabled hidden>Select an option</option>
                {   
                options.map((element, key) => (
                    <option key={key} value={element.value}>{element.text}</option>
                ))
                }
            </select>
            
            }
        </div>
    )
};

const postUploadFile = (accessToken,file,uploadData,setUploadedFileName,setErrorList) => {
    ConversionService.uploadImport(accessToken, file, uploadData).then(response => {
        if(response.status > 199 && response.status < 300){
            console.log(response.data)
            setUploadedFileName(response.data.fileName);
            setErrorList(response.data.errorList);
        }
        else{
            setErrorList(['Server Failed to upload and convert file.']);
        }
    });
};

const downloadUploadedFile = (accessToken,fileName) => {
    ConversionService.downloadImportedFile(accessToken, fileName)
};

const Conversion = () => {
    const contextObject = useContext(Context);

    const [ typeSelection, setTypeSelection ] = useState('');
    const [ codeDirection, setCodeDirection ] = useState('');
    const [ readyToUpload, setReadyToUpload ] = useState(false);
    const [ uploadFile, setUploadFile ] = useState();
    const [ uploading, setUploading ] = useState(false);
    const [ uploadedFileName, setUploadedFileName ] = useState('');
    const [ uploadErrorList, setUploadErrorList ] = useState([]);

    const checkReadyToUpload = () => {
        let ready = true;

        if(typeSelection){
            if(typeSelection === 'cost'){
                if(!codeDirection) ready = false;
            };
        }
        else ready = false;

        return ready;
    };

    useEffect(() => {
        setReadyToUpload(checkReadyToUpload());
    },[typeSelection,codeDirection]);

    const uploadFileToConvert = () => {
        setUploading(true);
        const uploadData = {
            typeSelection:typeSelection,
            codeDirection:codeDirection,
        }
        postUploadFile(contextObject.apiToken,uploadFile,uploadData,setUploadedFileName,setUploadErrorList);
    };

    const getFormattedFile = () => {
        downloadUploadedFile(contextObject.apiToken,uploadedFileName);
    };

    const resetConversion = () => {
        setTypeSelection('');
        setCodeDirection('');
        setReadyToUpload(false);
        setUploadFile(null);
        setUploading(false);
        setUploadedFileName('');
        setUploadErrorList([]);
    };

    useEffect(() => {
        if(uploadedFileName || uploadErrorList) setUploading(false);
    },[uploadedFileName,uploadErrorList]);

    return(
        <div className='Conversion-Window'>
            <div className='Conversion-Header'>
                <div className='Conversion-Header-Title'>
                    Cost Code & GL String Conversion
                </div>
            </div>
            {   uploadedFileName &&
                <div className='Conversion-Reset'>
                    <button className='Conversion-Reset-Button' onClick={() => resetConversion()}>Reset Conversion System</button>
                </div>
            }
            {   !uploading && !uploadedFileName &&
            <>
                <div className='Conversion-Selection'>
                    <SelectInputField title={"Select the type of conversion you would like to perform"} value={typeSelection} setTo={setTypeSelection} options={selectionOptions}/>

                    { typeSelection === 'cost' &&
                    <>
                        <div className='Conversion-Code-Selection'>
                            <SelectInputField title={"Select the direction you would like to convert"} value={codeDirection} setTo={setCodeDirection} options={codeDirectionOptions}/>
                        </div>
                        {   codeDirection === 'old' &&
                        <div className='Conversion-Text-Section'>
                            <div className='Conversion-Text-Line'>
                                This will search the excel document you upload for cost code strings with the following format '##.##.##.##' Each occurrence will refer to the mapping sheet below and replace the old cost code provided with the new version.
                            </div>
                            <a className='Conversion-Text-Line' href="https://cornerstonedetentionproducts.box.com/s/hbzrr3eq25bk7nsih3fl2bekf4wzi2vr" target="_blank" rel="noopener noreferrer">Cost Code Mapping Document</a>
                        </div>
                        }
                        {   codeDirection === 'new' &&
                        <div className='Conversion-Text-Section'>
                            <div className='Conversion-Text-Line'>
                                This will search the excel document you upload for cost code strings with the following format '##.##.##.##' Each occurrence will refer to the mapping sheet below and replace the new cost code provided with the old version.
                            </div>
                            <a className='Conversion-Text-Line' href="https://cornerstonedetentionproducts.box.com/s/hbzrr3eq25bk7nsih3fl2bekf4wzi2vr" target="_blank" rel="noopener noreferrer">Cost Code Mapping Document</a>
                        </div>
                        }
                    </>
                    }
                    { typeSelection === 'gl' &&
                    <>
                    <div className='Conversion-Text-Section'>
                        <div className='Conversion-Text-Line'>
                            This will search the excel document you upload for gl strings with the following format '#-##-##-####' Each occurrence will be split into additional columns to repressent the Company - Branch - Subaccount - Account.
                        </div>
                    </div>
                    </>
                    }
                </div>

                {   readyToUpload &&
                    <div className='Conversion-Import'>
                        <div className='Conversion-Import-Instructions'>
                            Select the excel file to convert.
                        </div>
                        <input type='file' accept=".xls,.xlsx" onChange={(e) => setUploadFile(e.target.files)} className='Conversion-Import-Upload'/>
                        
                        {   readyToUpload && uploadFile &&
                        <button className='Conversion-Import-Submit' onClick={() => uploadFileToConvert()}>Convert</button>
                        }
                    </div>
                }

            </>
            }
            {   uploading &&
            <>
                <div className='Conversion-Uploading'>
                    <LoadingSpinner size={120}/>
                    <div className=''>
                        Please Wait... Converting File...
                    </div>
                </div>
            </>
            }
            {   uploadedFileName &&
            <>
                <div className='Conversion-Download'>
                    <div className='Conversion-Download-Label'>
                        Your file has been converted and is ready to be downloaded.
                    </div>
                    <button className='Conversion-Import-Download' onClick={() => getFormattedFile()}>Download Formatted File</button>
                </div>
            </>
            }
            {   uploadErrorList.length > 0 &&
            <>
                <div className='Conversion-Error'>
                    <div className='Conversion-Error-Label'>
                        Error Log
                    </div>
                    {
                        uploadErrorList.map(error => (
                            <div className='Conversion-Error-Line'>
                                {error}
                            </div>
                        ))
                    }
                </div>
            </>
            }
        </div>
    )
};

export default Conversion;