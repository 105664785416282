import './NewCustomer.css';
import {useEffect, useState} from 'react';
import Logo from '../refs/CornerstoneColor.jpg';
import { useSearchParams } from "react-router-dom";
import { ImCheckboxUnchecked, ImCheckboxChecked  } from "react-icons/im";
import { FaStar, FaCheck, FaDotCircle  } from "react-icons/fa";
import { HiBadgeCheck } from "react-icons/hi";
import LoadingSpinner from '../Components/LoadingSpinner';

import newCustomerDataService from '../Services/NewCustomerService';

// CTRL+K+0 = Collapse ALL
// CTRL+K+} = expand current block
// CTRL+K+{ =collapse current block


// SERVER INTERACTION - - - - - - -

// SEND OBJECT TO BACK END
const submitCustomerRequest = async (customerObject,setFormSubmissionValid) => {
    newCustomerDataService.submitCustomerRecord(customerObject).then(response => {
        if(response.status > 199 && response.status < 300) setFormSubmissionValid(true);
        else setFormSubmissionValid(false);
    })
};

const sendW9 = async (w9, filename, setW9Id, setW9Error) => {
    newCustomerDataService.submitW9(w9,filename)
    .then(response =>{
        setW9Id(response.data.id);
    }).catch( e=> {
        setW9Error(e.toString());
    });
};

const sendInternationalWire = async (intWire, filename, setIntWireId, setIntWireError) => {
    newCustomerDataService.submitIntWire(intWire,filename)
    .then(response =>{
        setIntWireId(response.data.id);
    }).catch( e=> {
        setIntWireError(e.toString());
    });
};

const sendVoidedCheck = async (voidCheck, filename, setVoidedCheckFileId, setVoidedCheckError) => {
    newCustomerDataService.submitVoidedCheck(voidCheck,filename)
    .then(response =>{
        setVoidedCheckFileId(response.data.id);

    }).catch( e=> {
        setVoidedCheckError(e.toString());

    });
};

const sendTaxCert = async (taxCert, filename, setTaxCertId, setTaxCertError) => {
    newCustomerDataService.submitTaxExemption(taxCert,filename)
    .then(response =>{
        setTaxCertId(response.data.id);

    }).catch( e=> {
        setTaxCertError(e.toString());
    });
};

const sendCreditReferenceSheet = async (creditReferenceSheet, filename, setCreditReferenceSheetId, setCreditReferenceSheetError) => {
    newCustomerDataService.submitCreditReferencesSheet(creditReferenceSheet,filename)
    .then(response =>{
        setCreditReferenceSheetId(response.data.id);

    }).catch( e=> {
        setCreditReferenceSheetError(e.toString());
    });
};

// HELPER FUNCTIONS - - - - - - - - - - -
const getDate = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    
    today = mm + '.' + dd + '.' + yyyy;
    return today;
};


const SignatureInput = ({title,setTo,complete=false ,width=0}) => {
    const [displayWidth, setDisplayWidth ] = useState('auto');

    const inputCssProps = {
        '--inputWidth': `${displayWidth}`
    };

    useEffect(() => {
        if(width > 0) setDisplayWidth(`${width}px`)
        else setDisplayWidth('auto')
    },[])

    return(
        <>
        <link rel="preconnect" href="https://fonts.googleapis.com"/>
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin='true'/>
        <link href="https://fonts.googleapis.com/css2?family=Whisper&display=swap" rel="stylesheet"/>

        <div className='External-Form-Input' style={inputCssProps}>
            {   !complete &&
                <FaStar className='External-Form-Input-Required' />
            }
            {   complete &&
                <FaCheck className='External-Form-Input-Complete' />
            }
            
            <div className='External-Form-Input-Title'>
                {title}
            </div>
            <input type="text" className='External-Form-Signature-Box' onChange={(e) => setTo(e.target.value)} />
        </div>
        </>
    )
}

const TextInput = ({title,setTo, complete=false ,width=0,charCount=0,required=false,active=true, under=false, initializedValue=null}) => {
    const [displayWidth, setDisplayWidth ] = useState('auto');

    const inputCssProps = {
        '--inputWidth': `${displayWidth}`
    };

    useEffect(() => {
        if(width>0){
            setDisplayWidth(`${width}px`)
        }
        else{
            if(charCount > 0){
                setDisplayWidth(`${charCount*18}px`);
            }
            else{
                setDisplayWidth('auto')
            }
        }
    },[])

    if(!under){
        return(
            <div className='External-Form-Input' style={inputCssProps}>
                {   active && required && !complete &&
                <FaStar className='External-Form-Input-Required' />
                }
                {   active && !required && !complete &&
                <FaStar className='External-Form-Input-Not-Required' />
                }
                {   active && complete &&
                <FaCheck className='External-Form-Input-Complete' />
                }
                <div className='External-Form-Input-Title'>
                    {title}
                </div>
                {   charCount > 0 &&
                    <input type="text" placeholder={initializedValue} inputMode='numeric' maxLength={charCount} className='External-Form-Input-Contrained' onChange={(e) => setTo(e.target.value)}/>
                }
                {   charCount < 1 &&
                    <input type="text" placeholder={initializedValue} className='External-Form-Input-Box' onChange={(e) => setTo(e.target.value)} />
                }
                
            </div>
        )
    }
    else{
        return(
            <div className='External-Form-Input-Under' style={inputCssProps}>
                {   active && required && !complete &&
                <FaStar className='External-Form-Input-Required' />
                }
                {   active && !required && !complete &&
                <FaStar className='External-Form-Input-Not-Required' />
                }
                {   active && complete &&
                <FaCheck className='External-Form-Input-Complete' />
                }

                {   charCount > 0 &&
                    <input type="text" inputMode='numeric' placeholder={initializedValue} maxLength={charCount} className='External-Form-Input-Contrained' onChange={(e) => setTo(e.target.value)}/>
                }
                {   charCount < 1 &&
                    <input type="text" className='External-Form-Input-Box' placeholder={initializedValue} onChange={(e) => setTo(e.target.value)} />
                }

                <div className='External-Form-Input-Title-Under'>
                    {title}
                </div>
                
            </div>
        )
    }

};

const DropDownSelection = ({title, options, setTo, complete=false, width, active, required, initializedValue}) => {
    const inputCssProps = {
        '--inputWidth': `${width}`
    };

    const selectOption = (value) => {
         setTo(value);
    };

    return(
        <div className='External-Form-Input' style={inputCssProps}>
        {   active && required && !complete &&
        <FaStar className='External-Form-Input-Required' />
        }
        {   active && required && complete &&
        <FaCheck className='External-Form-Input-Complete' />
        }
        <div className='External-Form-Input-Title'>
            {title}
        </div>
        {   !initializedValue &&
        <select className='External-Form-Dropdown'onChange={(e) => selectOption(e.target.value)}>
            <option value={false}> </option>
            {   options.map((option, key) => (
                <option className='External-Form-Dropdown-Selection' key={key} value={option}>{option}</option>
            ))
            }
        </select>
        }
        {   initializedValue &&
        <select className='External-Form-Dropdown' onChange={(e) => selectOption(e.target.value)}>
            <option value={true}>{initializedValue}</option>
            {   options.map((option, key) => (
                <option className='External-Form-Dropdown-Selection' key={key} value={option}>{option}</option>
            ))
            }
        </select>
        }

        
    </div>
    )
};

const AccountSelector = ({title, selected, setTo}) => {
    return(
        <div className='External-Vendor-Type-Selection-Group'>
            {   !selected &&
                <ImCheckboxUnchecked className='External-Vendor-Type-Selection' onClick={() => setTo(true)}/>
            }
            {   selected &&
                <ImCheckboxChecked className='External-Vendor-Type-Selection' onClick={() => setTo(false)}/>
            }
            <div className='External-Vendor-Type-Selection-Text'>
                {title}
            </div>
        </div>
    )
};

const ClassificationSelector = ({value,classificationList,addClassification,removeClassification}) => {
    const [selected, setSelected] = useState(false);

    useEffect(() => {
        if(classificationList){
            if(classificationList.includes(value)){
                setSelected(true);
            }
            else setSelected(false);
        }
        else setSelected(false);

    },[classificationList]);

    return(
        <div className='External-Vendor-Type-Selection-Group'>
            {   !selected &&
                <ImCheckboxUnchecked className='External-Vendor-Type-Selection' onClick={() => addClassification(value)}/>
            }
            {   selected &&
                <ImCheckboxChecked className='External-Vendor-Type-Selection' onClick={() => removeClassification(value)}/>
            }
            <div className='External-Vendor-Type-Selection-Text'>
                {value}
            </div>
        </div>
    )
};

const checkEmail = (email) => {
    let valid = true;
    try{
        let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    
        if(re.test(email)){
            // Valid Email
        }
        else{
            valid = false;
        }
    }
    catch{
        valid = false;
    }

    return valid;
};


// DISPLAY SECTIONS - - - - - - - - - -
const FormHeader = ({formEmail}) => {
    return(
        <div className='External-Form-Header'>
            <img src={Logo} className='External-Form-Logo' alt='Cornerstone Logo'/>

            <div className='External-Form-Contact'>
                <div className='External-Form-Contact-Content'>
                    2511 Midpark Road, Montgomery, AL 36109
                </div>
                <div className='External-Form-Contact-Content'>
                    (334) 286-4333
                </div>
            </div>
            <div className='External-Form-Email-Support'>
                {formEmail}
            </div>
            <div className='External-Vendor-Title'>
                    New CUSTOMER FORM
            </div>
        </div>
    )
};

// Customer INFORMATION SECTION
const CustomerInformation = ({CustomerInformationActive,setFedEinSSn,fedEinSSn,fedEinSSnComplete,setDunsNo,dunsNo,dunsNoComplete,setWebsite,
    website,websiteComplete,setLegalName,legalName,legalNameComplete,setTradeNameDBA,tradeNameDBA,tradeNameDBAComplete,setStreet,street,setStreet2,
    street2,streetComplete,setCity,city,cityComplete,setState,state,stateComplete,setZip,zip,zipComplete,setCountry,country,setShippingStreet,
    shippingStreet,shippingStreetComplete,setShippingStreet2,shippingStreet2,setShippingCity,shippingCity,shippingCityComplete,setShippingState,
    shippingState,shippingStateComplete,setShippingZip,shippingZip,shippingZipComplete,setShippingCountry,shippingCountry,setPrimaryContactFirstName,
    primaryContactFirstName,setPrimaryContactLastName,primaryContactLastName,primaryContactNameComplete,setPrimaryContactNumber,primaryContactNumber,
    primaryContactNumberComplete,setPrimaryExtension,primaryExtension,setPrimaryTitle,primaryTitle,primaryTitleComplete,setPrimaryContactEmail,
    primaryContactEmail,primaryContactEmailComplete,setPrimaryStreet,primaryStreet,primaryStreetComplete,setPrimaryStreet2,primaryStreet2,setPrimaryCity,
    primaryCity,primaryCityComplete,setPrimaryState,primaryState,primaryStateComplete,setPrimaryZip,primaryZip,primaryZipComplete,setPrimaryCountry,
    primaryCountry,taxExemptOptions,setTaxExemptCert,taxExemptCert,taxExemptCertComplete,setContactFirstNameAP,contactFirstNameAP,setContactLastNameAP,contactLastNameAP,
    contactNameAPComplete,setPhoneAP,phoneAP,phoneAPComplete,setContactEmailAP,contactEmailAP,contactEmailAPComplete,paymentOptions,setPaymentMethod,
    paymentMethod,paymentMethodComplete,businessTypeOptions,setBusinessTypeSelection,businessTypeSelection,businessTypeSelectionComplete,setStateOfInc,stateOfInc,stateOfIncComplete,}) => {

    const [addressComplete, setAddressComplete] = useState(false);

    useEffect(() => {
        if(stateComplete && cityComplete && stateComplete && zipComplete) setAddressComplete(true);
        else setAddressComplete(false);
    },[streetComplete,cityComplete,stateComplete,zipComplete]);

    return(
        <>
        {   
            <div className='External-Customer-Information'>
                {   CustomerInformationActive &&
                    <>
                    <div className='External-Customer-Title External-Customer-Bold'>
                        Please Fill in Your Information Below.
                    </div>
                    </>

                }

               { <div className='External-Company-Section'>
                          Company Information
                </div>
                }
                <div className='External-Form-Spacer'>

                </div>

                <TextInput title={'Federal EIN/SSN:'} setTo={setFedEinSSn} complete={fedEinSSnComplete} charCount={9} required={true} active={CustomerInformationActive} initializedValue={fedEinSSn}/>
                <TextInput title={'Duns No.:'} setTo={setDunsNo} complete={dunsNoComplete} charCount={9} required={true} active={CustomerInformationActive} initializedValue={dunsNo}/>
                <TextInput title={'Website:'} setTo={setWebsite} complete={websiteComplete} width={340} required={false} active={CustomerInformationActive} initializedValue={website}/>
                <TextInput title={'Legal Name:'} setTo={setLegalName} complete={legalNameComplete} width={340} required={true} active={CustomerInformationActive} initializedValue={legalName}/>
                <TextInput title={'Trade Name/DBA:'} setTo={setTradeNameDBA} complete={tradeNameDBAComplete} width={340} required={true} active={CustomerInformationActive} initializedValue={tradeNameDBA}/>

                <div className='External-Form-Spacer'>

                </div>
                <div className='External-Form-Input-Title'>
                Billing Address:
                </div>
                <TextInput title={'Street:'} setTo={setStreet} complete={streetComplete} width={220} required={true} active={CustomerInformationActive} under={true} initializedValue={street}/>
                <TextInput title={'Street2:'} setTo={setStreet2} width={120} required={false} active={CustomerInformationActive} under={true} initializedValue={street2}/>
                <TextInput title={'City:'} setTo={setCity} complete={cityComplete} width={140} required={true} active={CustomerInformationActive} under={true} initializedValue={city}/>
                <TextInput title={'State:'} setTo={setState} complete={stateComplete} charCount={2} width={40} required={true} active={CustomerInformationActive} under={true} initializedValue={state}/>
                <TextInput title={'Zip:'} setTo={setZip} complete={zipComplete} charCount={5} required={true} active={CustomerInformationActive} under={true} initializedValue={zip}/>
                <TextInput title={'Country:'} setTo={setCountry} charCount={2} required={false} active={CustomerInformationActive} under={true} initializedValue={country}/>

                <div className='External-Form-Spacer'>
                </div>
                <div className='External-Form-Input-Title'>
                Shipping Address:
                </div>
                <TextInput title={'Street:'} setTo={setShippingStreet} complete={shippingStreetComplete} width={220} required={true} active={CustomerInformationActive} under={true} initializedValue={shippingStreet}/>
                <TextInput title={'Street2:'} setTo={setShippingStreet2} width={120} required={false} active={CustomerInformationActive} under={true} initializedValue={shippingStreet2}/>
                <TextInput title={'City:'} setTo={setShippingCity} complete={shippingCityComplete} width={140} required={true} active={CustomerInformationActive} under={true} initializedValue={shippingCity}/>
                <TextInput title={'State:'} setTo={setShippingState} complete={shippingStateComplete} charCount={2} width={40} required={true} active={CustomerInformationActive} under={true} initializedValue={shippingState}/>
                <TextInput title={'Zip:'} setTo={setShippingZip} complete={shippingZipComplete} charCount={5} required={true} active={CustomerInformationActive} under={true} initializedValue={shippingZip}/>
                <TextInput title={'Country:'} setTo={setShippingCountry} charCount={2} required={false} active={CustomerInformationActive} under={true} initializedValue={shippingCountry}/>

                <div className='External-Form-Spacer'>
                </div>
                
                <TextInput title={'Contact First Name (Primary):'} setTo={setPrimaryContactFirstName} complete={primaryContactNameComplete} width={150} required={true} active={CustomerInformationActive} initializedValue={primaryContactFirstName}/>
                <TextInput title={'Contact Last Name (Primary):'} setTo={setPrimaryContactLastName} complete={primaryContactNameComplete} width={150} required={true} active={CustomerInformationActive} initializedValue={primaryContactLastName}/>
                <TextInput title={'Contact Title:'} setTo={setPrimaryTitle} complete={primaryTitleComplete} width={150} required={true} active={CustomerInformationActive} initializedValue={primaryTitle}/>
                <TextInput title={'Contact Phone:'} setTo={setPrimaryContactNumber} complete={primaryContactNumberComplete} charCount={10} required={true} active={CustomerInformationActive} initializedValue={primaryContactNumber}/>
                <TextInput title={'Contact Extension'} setTo={setPrimaryExtension} charCount={6} required={false} active={CustomerInformationActive}  initializedValue={primaryExtension}/>
                
                <div className='External-Form-Spacer'>
                </div>

                <div className='External-Form-Input-Title'>
                Contact Home Address:
                </div>
               
                <TextInput title={'Street:'} setTo={setPrimaryStreet} complete={primaryStreetComplete} width={220} required={true} active={CustomerInformationActive} under={true} initializedValue={primaryStreet}/>
                <TextInput title={'Street2:'} setTo={setPrimaryStreet2} width={120} required={false} active={CustomerInformationActive} under={true} initializedValue={primaryStreet2}/>
                <TextInput title={'City:'} setTo={setPrimaryCity} complete={primaryCityComplete} width={140} required={true} active={CustomerInformationActive} under={true} initializedValue={primaryCity}/>
                <TextInput title={'State:'} setTo={setPrimaryState} complete={primaryStateComplete} charCount={2} width={40} required={true} active={CustomerInformationActive} under={true} initializedValue={primaryState}/>
                <TextInput title={'Zip:'} setTo={setPrimaryZip} complete={primaryZipComplete} charCount={5} required={true} active={CustomerInformationActive} under={true} initializedValue={primaryZip}/>
                <TextInput title={'Country:'} setTo={setPrimaryCountry} charCount={2} required={false} active={CustomerInformationActive} under={true} initializedValue={primaryCountry}/>

                
                <TextInput title={'Email Address:'} setTo={setPrimaryContactEmail} complete={primaryContactEmailComplete} width={340} required={true} active={CustomerInformationActive} initializedValue={primaryContactEmail}/>
                <DropDownSelection title={'Tax Exempt:'} setTo={setTaxExemptCert} complete={taxExemptCertComplete} options={taxExemptOptions} width={200} required={true} active={CustomerInformationActive} initializedValue={taxExemptCert}/>
                <TextInput title={'Contact First Name (Account Payable):'} setTo={setContactFirstNameAP} complete={contactNameAPComplete} width={150} required={true} active={CustomerInformationActive} initializedValue={contactFirstNameAP}/>
                <TextInput title={'Contact Last Name (Account Payable):'} setTo={setContactLastNameAP} complete={contactNameAPComplete} width={150} required={true} active={CustomerInformationActive} initializedValue={contactLastNameAP}/>
                <TextInput title={'Phone:'} setTo={setPhoneAP} complete={phoneAPComplete} width={340} charCount={10}  required={true} active={CustomerInformationActive} initializedValue={phoneAP}/>
                <TextInput title={'Email Address:'} setTo={setContactEmailAP} complete={contactEmailAPComplete} width={340} required={true} active={CustomerInformationActive} initializedValue={contactEmailAP}/>
                <DropDownSelection title={'Payment Method:'} setTo={setPaymentMethod} complete={paymentMethodComplete} options={paymentOptions} width={200} required={true} active={CustomerInformationActive} initializedValue={paymentMethod}/>
                <DropDownSelection title={'Business Type:'} setTo={setBusinessTypeSelection} complete={businessTypeSelectionComplete} options={businessTypeOptions} width={200} required={true} active={CustomerInformationActive} initializedValue={businessTypeSelection}/>
                <TextInput title={'State Of Incorporation:'} setTo={setStateOfInc} complete={stateOfIncComplete} width={100} charCount={2} required={true} active={CustomerInformationActive} initializedValue={stateOfInc}/>
                

                
            </div>
        }
        </>
    )
};

/*
const TaxCertificateSection = ({taxCertActive,setTaxCert}) => {
    return (
        <div className='External-Customer-Information'>
            {taxCertActive &&
                <>
                <div className='External-Tax-Form-Title'>
                    Tax Certificate
                </div>
                </>
            }
            {
                !taxCertActive &&
                <div className='External-Customer-Thick-Spacer' />
            }
            <div className='External-Form-Spacer'>
            </div>
            <div className='External-Form-Input-Title'>
           Place Your Certificate of Tax Exemption Below:
            </div>
            <input type='file' accept="application/pdf" onChange={(e) => setTaxCert(e.target.files)} className='External-Form-File-Upload'/>
        </div>
    )
};
*/

const CreditCardInformation = ({creditCardActive,setNameOnCard,nameOnCard,nameOnCardComplete,setCardNumber,cardNumber,cardNumberComplete,setExpDate,expDate,
    expDateComplete,setCvv,cvv,cvvComplete,setCardZip,cardZip,cardZipComplete}) => {
    return(
        <div className='External-Customer-Card-Information'>
            <div className='External-Customer-Card-Title'>
               Credit Card Information
            </div>

            <div className='External-Form-Spacer'>
            </div>

            <div className='External-Card-Information-Group'>
            <TextInput title={'Name On Card:'} setTo={setNameOnCard} complete={nameOnCardComplete} width={340} required={true} active={creditCardActive} initializedValue={nameOnCard}/>
            <TextInput title={'Card Number:'} setTo={setCardNumber} complete={cardNumberComplete} width={340} charCount={19} required={true} active={creditCardActive} initializedValue={cardNumber}/>
            
            <div className='External-Form-Spacer'>
            </div>

            <TextInput title={'Exp Date:'} setTo={setExpDate} complete={expDateComplete} width={140} required={true} active={creditCardActive} initializedValue={expDate}/>
            <TextInput title={'Security Code:'} setTo={setCvv} complete={cvvComplete} charCount={4}  required={true} active={creditCardActive} initializedValue={cvv}/>
            <TextInput title={'Zip:'} setTo={setCardZip} complete={cardZipComplete} charCount={5} required={true} active={creditCardActive} initializedValue={cardZip}/>
            </div>

        
        </div>
    )
};

const AchInformation = ({achActive,setBankName,bankName,bankNameComplete,setBankContactFirstName,bankContactFirstName,setBankContactLastName,bankContactLastName,bankContactNameComplete,
    setBankStreet,bankStreet,setBankStreet2,bankStreet2,bankStreetComplete,setBankCity,bankCity,bankCityComplete,setBankState,bankState,bankStateComplete,
    setBankZip,bankZip,bankZipComplete,setBankCountry,bankCountry,bankCountryComplete,setBankTelephone,bankTelephone,bankTelephoneComplete,
    setBankExtension,bankExtension,setBankContactTitle,bankContactTitle,bankContactTitleComplete,setBankContactEmail,bankContactEmail,bankContactEmailComplete,
    setBankAccountName,bankAccountName,bankAccountNameComplete,setBankAccountNumber,bankAccountNumber,bankAccountNumberComplete,setBankRoutingNumber,bankRoutingNumber,
    bankRoutingNumberComplete,setBankSwiftCode,bankSwiftCode,bankSwiftCodeComplete,setBankAccountSelection,bankAccountSelection,bankAccountSelectionComplete}) => {
    
    const [ checkingSelected, setCheckingSelected ] = useState(true);
    const [ savingsSelected, setSavingsSelected ] = useState(false);
    const [ addressComplete, setAddressComplete] = useState(false);

    useEffect(() => {
        if(bankStateComplete && bankCityComplete && bankStateComplete && bankZipComplete) setAddressComplete(true);
        else setAddressComplete(false);
    },[bankStateComplete,bankCityComplete,bankStateComplete,bankZipComplete]);


    const toggleSelection = (value) => {
        if(value === 'Checking'){
            setSavingsSelected(false);
            setCheckingSelected(true);
            setBankAccountSelection('Checking');
        }
        else{
            setSavingsSelected(true);
            setCheckingSelected(false);
            setBankAccountSelection('Savings');
        }
    };

    useEffect(() => {
        if(bankAccountSelection === 'Checking'){
            setSavingsSelected(false);
            setCheckingSelected(true);
        }
        else{
            setSavingsSelected(true);
            setCheckingSelected(false);
        }
    },[bankAccountSelection]);

    return(
        <div className='External-Customer-Information'>
            <div className='External-Customer-ACH-Title'>
                Banking Information
            </div>

            

            <div className='External-Form-Spacer'>
            </div>
                
            <TextInput title={'Bank Name:'} setTo={setBankName} complete={bankNameComplete} width={280} required={true} active={achActive} initializedValue={bankName}/>
            <TextInput title={'Name on Account:'} setTo={setBankAccountName} complete={bankAccountNameComplete} width={300} required={true} active={achActive} initializedValue={bankAccountName}/>

            <div className='External-Form-Spacer'>
            </div>
            <div className='External-Form-Input-Title'>
            Bank Address:
            </div>
            <TextInput title={'Street:'} setTo={setBankStreet}complete={bankStreetComplete} width={220} required={true} active={achActive} under={true} initializedValue={bankStreet}/>
            <TextInput title={'Street2:'} setTo={setBankStreet2} width={120} required={false} active={achActive} under={true} initializedValue={bankStreet2}/>
            <TextInput title={'City:'} setTo={setBankCity} complete={bankCityComplete} width={140} required={true} active={achActive} under={true} initializedValue={bankCity}/>
            <TextInput title={'State:'} setTo={setBankState} complete={bankStateComplete} charCount={2} width={40} required={true} active={achActive} under={true} initializedValue={bankState}/>
            <TextInput title={'Zip:'} setTo={setBankZip} complete={bankZipComplete} charCount={5} required={true} active={achActive} under={true} initializedValue={bankZip}/>
            <TextInput title={'Country:'} setTo={setBankCountry} complete={bankCountryComplete} charCount={2} required={false} active={achActive} under={true} initializedValue={bankCountry}/>
            <div className='External-Form-Spacer'>
            </div>

            <TextInput title={'Bank Telephone:'} setTo={setBankTelephone} complete={bankTelephoneComplete} charCount={10} required={true} active={achActive} initializedValue={bankTelephone}/>
            <TextInput title={'Bank Telephone Extension:'} setTo={setBankExtension} charCount={6} required={false} active={achActive}  initializedValue={bankExtension}/>
            <TextInput title={'Bank Contact First Name:'} setTo={setBankContactFirstName} complete={bankContactNameComplete} width={150} required={true} active={achActive} initializedValue={bankContactFirstName}/>
            <TextInput title={'Bank Contact Last Name:'} setTo={setBankContactLastName} complete={bankContactNameComplete} width={150} required={true} active={achActive} initializedValue={bankContactLastName}/>
            <TextInput title={'Bank Contact Title:'} setTo={setBankContactTitle} complete={bankContactTitleComplete} width={340} required={true} active={achActive} initializedValue={bankContactTitle}/>
            <TextInput title={'Bank Contact Email:'} setTo={setBankContactEmail} complete={bankContactEmailComplete} width={300} required={true} active={achActive} initializedValue={bankContactEmail}/>
            <TextInput title={'Account Number:'} setTo={setBankAccountNumber} complete={bankAccountNumberComplete} charCount={17} required={true} active={achActive} initializedValue={bankAccountNumber}/>
            <TextInput title={'Routing Number:'} setTo={setBankRoutingNumber} complete={bankRoutingNumberComplete} charCount={9} required={true} active={achActive} initializedValue={bankRoutingNumber}/>
            <TextInput title={'Swift Code:'} setTo={setBankSwiftCode} complete={bankSwiftCodeComplete} charCount={11}  active={achActive} initializedValue={bankSwiftCode}/>

            <AccountSelector title={'Checking'} selected={checkingSelected} setTo={() => toggleSelection('Checking')}/>
            <AccountSelector title={'Savings'} selected={savingsSelected} setTo={() => toggleSelection('Savings')}/>

            </div>
    )
};    


//Required Files 
const RequiredFilesSection = ({paymentMethod,businessTypeSelection,setW9,w9Active,intWireActive,voidedCheckActive,taxCertActive,setIntWire,setFinishedW9,
    setVoidedCheckFile,setTaxCert,setCreditReferenceSheet}) => {
    //console.log(paymentMethod)
    return(
        <div className='External-Required-Files'>
            <div className='External-Customer-Required-Files-Title'>
                Required Files 
            </div>

            <div className='External-Form-Spacer'>
            </div>

            <div className='External-Form-Input-Row-Files'>
                <div className='External-Form-Input-Title'>
                PDF version of completed W9:
                </div>
                <input type='file' accept="application/pdf" onChange={(e) => setW9(e.target.files)} className='External-Form-File-Upload'/>
            </div>

            { businessTypeSelection !== 'Government' &&
            <div className='External-Form-Input-Row-Files'>
                <div className='External-Form-Input-Title'>
                    PDF version of Credit Reference Sheet:
                </div>
            <input type='file' accept="application/pdf" onChange={(e) => setCreditReferenceSheet(e.target.files)} className='External-Form-File-Upload'/>
            </div>
            }


            {   paymentMethod === 'ACH' &&
            <div className='External-Form-Input-Row-Files'>
                <div className='External-Form-Input-Title'>
                    PDF version of Voided Check or Bank Letter:
                </div>
                <input type='file' accept="application/pdf" onChange={(e) => setVoidedCheckFile(e.target.files)} className='External-Form-File-Upload'/>
            </div>
            }

            {   paymentMethod === 'International Wire' &&
            <div className='External-Form-Input-Row-Files'>
                <div className='External-Form-Input-Title'>
                  PDF version of International Wire Instructions:
                </div>
                <input type='file' accept="application/pdf" onChange={(e) => setIntWire(e.target.files)} className='External-Form-File-Upload'/>
            </div>
            }

            {   taxCertActive &&
            <div className='External-Form-Input-Row-Files'>
                <div className='External-Form-Input-Title'>
                    PDF version of Certificate of Tax Exemption:
                </div>
                <input type='file' accept="application/pdf" onChange={(e) => setTaxCert(e.target.files)} className='External-Form-File-Upload'/>
            </div>
            }

        </div>
    )

};



//Owner/s or Authorized Officers
const OwnersOrOfficersInformation = ({OwnersOrOfficersInformationActive,setFirstName1,firstName1,setLastName1,lastName1,ownerName1Complete,setFirstName2,
firstName2,setLastName2,lastName2,ownerName2Complete,setTitle,title,titleComplete,setPhone,phone,phoneComplete,setExtension,extension,setOwnerStreet
,ownerStreet,ownerStreetComplete,setOwnerStreet2,ownerStreet2,setOwnerCity,ownerCity,ownerCityComplete,setOwnerState,ownerState,ownerStateComplete,setOwnerZip,ownerZip,
ownerZipComplete,setOwnerCountry,ownerCountry,setOwner2Street,owner2Street,owner2StreetComplete,setOwner2Street2,owner2Street2,setOwner2City,owner2City,owner2CityComplete,
setOwner2State,owner2State,owner2StateComplete,setOwner2Zip,owner2Zip,owner2ZipComplete,setOwner2Country,owner2Country,setEmailAddress,emailAddress,emailAddressComplete,
setSecondaryOwner,secondaryOwner,setTitle2,title2,title2Complete,setPhone2,phone2,phone2Complete,setExtension2,extension2,
setEmailAddress2,emailAddress2,emailAddress2Complete
}) => {
        return(
        <>
        {   
            <div className='External-Owners-Or-Officers-Information'>
                
                <div className='External-Owners-Section'>
                          Owner/s or Authorized Officers
                </div>

                <div className='External-Form-Spacer'>

                </div>
                <div className='External-Customer-Contact-Group'> 
                <TextInput title={'First Name:'} setTo={setFirstName1} complete={ownerName1Complete} width={200} required={true} active={OwnersOrOfficersInformationActive} initializedValue={firstName1}/>
                <TextInput title={'Last Name:'} setTo={setLastName1} complete={ownerName1Complete} width={200} required={true} active={OwnersOrOfficersInformationActive} initializedValue={lastName1}/>
                <TextInput title={'Title:'} setTo={setTitle} complete={titleComplete} width={150} required={true} active={OwnersOrOfficersInformationActive} initializedValue={title}/>
                <TextInput title={'Email Address:'} setTo={setEmailAddress} complete={emailAddressComplete} width={300} required={true} active={OwnersOrOfficersInformationActive} initializedValue={emailAddress}/>
                <TextInput title={'Phone:'} setTo={setPhone} complete={phoneComplete} charCount={10} required={true} active={OwnersOrOfficersInformationActive} initializedValue={phone}/>
                <TextInput title={'Extension'} setTo={setExtension} charCount={6} required={false} active={OwnersOrOfficersInformationActive}  initializedValue={extension}/>
                
                <div className='External-Form-Spacer'>

                </div>

                <div className='External-Form-Input-Title'>
                Home Address:
                </div>

                <TextInput title={'Street:'} setTo={setOwnerStreet} complete={ownerStreetComplete} width={220} required={true} active={OwnersOrOfficersInformationActive} under={true} initializedValue={ownerStreet}/>
                <TextInput title={'Street2:'} setTo={setOwnerStreet2} width={120} required={false} active={OwnersOrOfficersInformationActive} under={true} initializedValue={ownerStreet2}/>
                <TextInput title={'City:'} setTo={setOwnerCity} complete={ownerCityComplete} width={140} required={true} active={OwnersOrOfficersInformationActive} under={true} initializedValue={ownerCity}/>
                <TextInput title={'State:'} setTo={setOwnerState} complete={ownerStateComplete} charCount={2} width={40} required={true} active={OwnersOrOfficersInformationActive} under={true} initializedValue={ownerState}/>
                <TextInput title={'Zip:'} setTo={setOwnerZip} complete={ownerZipComplete} charCount={5} required={true} active={OwnersOrOfficersInformationActive} under={true} initializedValue={ownerZip}/>
                <TextInput title={'Country:'} setTo={setOwnerCountry} charCount={2} required={false} active={OwnersOrOfficersInformationActive} under={true} initializedValue={ownerCountry}/>

                
                </div>

                {   !secondaryOwner && 
                    <button onClick={() => setSecondaryOwner(true)} className='External-Customer-Contact-Button Button-Add'>Add Secondary Contact</button>
                }
                {   secondaryOwner && 
                   <button onClick={() => setSecondaryOwner(false)} className='External-Customer-Contact-Button Button-Remove'>Remove Secondary Contact</button>
                }
                { secondaryOwner && 
                <div className='External-Customer-Contact-Group'>
                    <TextInput title={'First Name:'} setTo={setFirstName2} complete={ownerName2Complete} width={200} required={true} active={OwnersOrOfficersInformationActive} initializedValue={firstName2}/>
                    <TextInput title={'Last Name:'} setTo={setLastName2} complete={ownerName2Complete} width={200} required={true} active={OwnersOrOfficersInformationActive} initializedValue={lastName2}/>
                    <TextInput title={'Title:'} setTo={setTitle2} complete={title2Complete} width={150} required={true} active={OwnersOrOfficersInformationActive} initializedValue={title2}/>
                    <TextInput title={'Email Address:'} setTo={setEmailAddress2} complete={emailAddress2Complete} width={300} required={true} active={OwnersOrOfficersInformationActive} initializedValue={emailAddress2}/>
                    <TextInput title={'Phone:'} setTo={setPhone2} complete={phone2Complete} charCount={10} required={true} active={OwnersOrOfficersInformationActive} initializedValue={phone2}/>
                    <TextInput title={'Extension'} setTo={setExtension2} charCount={6} required={false} active={OwnersOrOfficersInformationActive}  initializedValue={extension2}/>
                
                    <div className='External-Form-Spacer'>

                    </div>

                    <div className='External-Form-Input-Title'>
                    Home Address:
                    </div>

                    <TextInput title={'Street:'} setTo={setOwner2Street} complete={owner2StreetComplete} width={220} required={true} active={OwnersOrOfficersInformationActive} under={true} initializedValue={owner2Street}/>
                    <TextInput title={'Street2:'} setTo={setOwner2Street2} width={120} required={false} active={OwnersOrOfficersInformationActive} under={true} initializedValue={owner2Street2}/>
                    <TextInput title={'City:'} setTo={setOwner2City} complete={owner2CityComplete} width={140} required={true} active={OwnersOrOfficersInformationActive} under={true} initializedValue={owner2City}/>
                    <TextInput title={'State:'} setTo={setOwner2State} complete={owner2StateComplete} charCount={2} width={40} required={true} active={OwnersOrOfficersInformationActive} under={true} initializedValue={owner2State}/>
                    <TextInput title={'Zip:'} setTo={setOwner2Zip} complete={owner2ZipComplete} charCount={5} required={true} active={OwnersOrOfficersInformationActive} under={true} initializedValue={owner2Zip}/>
                    <TextInput title={'Country:'} setTo={setOwner2Country} charCount={2} required={false} active={OwnersOrOfficersInformationActive} under={true} initializedValue={owner2Country}/>

                </div>
                }
            </div>  

            

            
        }
        </>
        )
};

/*
//BANK REFERENCE
const BankReferenceInformation = ({BankReferenceInformationActive,setBankNameReference,bankNameReference,bankNameReferenceComplete,setBankContactName,bankContactName,bankContactNameReferenceComplete,
    setBankAddress,bankAddress,bankAddressComplete,setBankPhone,bankPhone,bankPhoneComplete,setBankEmail,bankEmail,bankEmailComplete,setNameOnAccount,
    nameOnAccount,nameOnAccountComplete
 }) => {
        return(
        <>
        {
            <div className='External-Bank-Reference-Section'>
                
                <div className='External-Bank-Reference-Title'>
                    Bank Reference
                </div>

                <div className='External-Form-Spacer'>

                </div>

                <TextInput title={'Bank Name:'} setTo={setBankNameReference} complete={bankNameReferenceComplete} width={340} required={true} active={BankReferenceInformationActive} initializedValue={bankNameReference}/>
                <TextInput title={'Bank Contact Name:'} setTo={setBankContactName} complete={bankContactNameReferenceComplete} width={340} required={true} active={BankReferenceInformationActive} initializedValue={bankContactName}/>
                <TextInput title={'Bank Address:'} setTo={setBankAddress} complete={bankAddressComplete} width={700} required={true} active={BankReferenceInformationActive} initializedValue={bankAddress}/>
                <TextInput title={'Bank Phone No:'} setTo={setBankPhone} complete={bankPhoneComplete} charCount={10} required={true} active={BankReferenceInformationActive} initializedValue={bankPhone}/>
                <TextInput title={'Bank Contact Email:'} setTo={setBankEmail} complete={bankEmailComplete} width={340} required={true} active={BankReferenceInformationActive} initializedValue={bankEmail}/>
                <TextInput title={'Name on Account:'} setTo={setNameOnAccount} complete={nameOnAccountComplete} width={700} required={true} active={BankReferenceInformationActive} initializedValue={nameOnAccount}/>


            </div> 
        }
        </>
    )
 };
*/

const TradeReferencesInformation = ({TradeReferencesInformationActive,setCompanyName,companyName,companyNameComplete,setContactFirstName,contactFirstName,
    setContactLastName,contactLastName,contactTradeNameComplete,setTradeTitle,tradeTitle,tradeTitleComplete,setTradeEmail,tradeEmail,tradeEmailComplete,
    setTradePhone,tradePhone,tradePhoneComplete,setTradeExtension,tradeExtension,setTradeStreet,tradeStreet,tradeStreetComplete,setTradeStreet2,tradeStreet2,
    setTradeCity,tradeCity,tradeCityComplete,setTradeState,tradeState,tradeStateComplete,setTradeZip,tradeZip,tradeZipComplete,setTradeCountry,tradeCountry,
    tradeCountryComplete,setSecondaryReference,secondaryReference,setCompanyName2,companyName2,companyName2Complete,setContactFirstName2,contactFirstName2,
    setContactLastName2,contactLastName2,contactTradeName2Complete,setTradeTitle2,tradeTitle2,tradeTitle2Complete,setTradeEmail2,tradeEmail2,tradeEmail2Complete,
    setTradePhone2,tradePhone2,tradePhone2Complete,setTradeExtension2,tradeExtension2,setTrade2Street,trade2Street,trade2StreetComplete,setTrade2Street2,
    trade2Street2,setTrade2City,trade2City,trade2CityComplete,setTrade2State,trade2State,trade2StateComplete,setTrade2Zip,trade2Zip,
    trade2ZipComplete,setTrade2Country,trade2Country,trade2CountryComplete,setThirdReference,thirdReference,setCompanyName3,companyName3,companyName3Complete,
    setContactFirstName3,contactFirstName3,setContactLastName3,contactLastName3,contactTradeName3Complete,setTradeTitle3,tradeTitle3,tradeTitle3Complete,
    setTradeEmail3,tradeEmail3,tradeEmail3Complete,setTradePhone3,tradePhone3,tradePhone3Complete,setTradeExtension3,tradeExtension3,setTrade3Street,trade3Street,
    trade3StreetComplete,setTrade3Street2,trade3Street2,setTrade3City,trade3City,trade3CityComplete,setTrade3State,trade3State,trade3StateComplete,setTrade3Zip,
    trade3Zip,trade3ZipComplete,setTrade3Country,trade3Country,trade3CountryComplete
}) => {
    return(
        <>
        {
            <div className='External-Trade-Reference-Information'>
                
                <div className='External-Trade-Reference-Title'>
                    Trade References
                </div>

                <div className='External-Form-Spacer'>

                </div>
                <div className='External-Trade-Group'>  
                <TextInput title={'Company Name:'} setTo={setCompanyName} complete={companyNameComplete} width={340} required={true} active={TradeReferencesInformationActive} initializedValue={companyName}/>
                <TextInput title={'Contact First Name:'} setTo={setContactFirstName} complete={contactTradeNameComplete} width={170} required={true} active={TradeReferencesInformationActive} initializedValue={contactFirstName}/>
                <TextInput title={'Contact Last Name:'} setTo={setContactLastName} complete={contactTradeNameComplete} width={170} required={true} active={TradeReferencesInformationActive} initializedValue={contactLastName}/>
                <TextInput title={'Title:'} setTo={setTradeTitle} complete={tradeTitleComplete} width={100} required={true} active={TradeReferencesInformationActive} initializedValue={tradeTitle}/>
                <TextInput title={'Email Address:'} setTo={setTradeEmail} complete={tradeEmailComplete} width={340} required={true} active={TradeReferencesInformationActive} initializedValue={tradeEmail}/>
                <TextInput title={'Phone No:'} setTo={setTradePhone} complete={tradePhoneComplete} width={340} charCount={10} required={true} active={TradeReferencesInformationActive} initializedValue={tradePhone}/>
                <TextInput title={'Extension:'} setTo={setTradeExtension}  charCount={6} required={false} active={TradeReferencesInformationActive} initializedValue={tradeExtension}/>
                
                <div className='External-Form-Spacer'>

                </div>

                <div className='External-Form-Input-Title'>
                Contact Home Address:
                </div>
                
                <TextInput title={'Street:'} setTo={setTradeStreet}complete={tradeStreetComplete} width={220} required={true} active={TradeReferencesInformationActive} under={true} initializedValue={tradeStreet}/>
                <TextInput title={'Street2:'} setTo={setTradeStreet2} width={120} required={false} active={TradeReferencesInformationActive} under={true} initializedValue={tradeStreet2}/>
                <TextInput title={'City:'} setTo={setTradeCity} complete={tradeCityComplete} width={140} required={true} active={TradeReferencesInformationActive} under={true} initializedValue={tradeCity}/>
                <TextInput title={'State:'} setTo={setTradeState} complete={tradeStateComplete} charCount={2} width={40} required={true} active={TradeReferencesInformationActive} under={true} initializedValue={tradeState}/>
                <TextInput title={'Zip:'} setTo={setTradeZip} complete={tradeZipComplete} charCount={5} required={true} active={TradeReferencesInformationActive} under={true} initializedValue={tradeZip}/>
                <TextInput title={'Country:'} setTo={setTradeCountry} complete={tradeCountryComplete} charCount={2} required={false} active={TradeReferencesInformationActive} under={true} initializedValue={tradeCountry}/>
                </div>

                <div className='External-Form-Spacer'>

                </div>

                {   !secondaryReference && 
                    <button onClick={() => setSecondaryReference(true)} className='External-Customer-Trade-Reference-Button Button-Add'>Add Second Reference</button>
                }
                {   secondaryReference && 
                   <button onClick={() => setSecondaryReference(false)} className='External-Customer-Trade-Reference-Button Button-Remove'>Remove Second Reference</button>
                }

                { secondaryReference && 
                <div className='External-Trade-Group'> 
                    <TextInput title={'Company Name:'} setTo={setCompanyName2} complete={companyName2Complete} width={340} required={true} active={TradeReferencesInformationActive} initializedValue={companyName2}/>
                    <TextInput title={'Contact First Name:'} setTo={setContactFirstName2} complete={contactTradeName2Complete} width={170} required={true} active={TradeReferencesInformationActive} initializedValue={contactFirstName2}/>
                    <TextInput title={'Contact Last Name:'} setTo={setContactLastName2} complete={contactTradeName2Complete} width={170} required={true} active={TradeReferencesInformationActive} initializedValue={contactLastName2}/>
                    <TextInput title={'Title:'} setTo={setTradeTitle2} complete={tradeTitle2Complete} width={170} required={true} active={TradeReferencesInformationActive} initializedValue={tradeTitle2}/>
                    <TextInput title={'Email Address:'} setTo={setTradeEmail2} complete={tradeEmail2Complete} width={280} required={true} active={TradeReferencesInformationActive} initializedValue={tradeEmail2}/>
                    <TextInput title={'Phone No:'} setTo={setTradePhone2} complete={tradePhone2Complete} charCount={10} required={true} active={TradeReferencesInformationActive} initializedValue={tradePhone2}/>
                    <TextInput title={'Extension:'} setTo={setTradeExtension2}  charCount={6} required={false} active={TradeReferencesInformationActive} initializedValue={tradeExtension2}/>
                  
                    <div className='External-Form-Spacer'>

                    </div>

                    <div className='External-Form-Input-Title'>
                    Contact Home Address:
                    </div>
                  
                    <TextInput title={'Street:'} setTo={setTrade2Street}complete={trade2StreetComplete} width={220} required={true} active={TradeReferencesInformationActive} under={true} initializedValue={trade2Street}/>
                    <TextInput title={'Street2:'} setTo={setTrade2Street2} width={120} required={false} active={TradeReferencesInformationActive} under={true} initializedValue={trade2Street2}/>
                    <TextInput title={'City:'} setTo={setTrade2City} complete={trade2CityComplete} width={140} required={true} active={TradeReferencesInformationActive} under={true} initializedValue={trade2City}/>
                    <TextInput title={'State:'} setTo={setTrade2State} complete={trade2StateComplete} charCount={2} width={40} required={true} active={TradeReferencesInformationActive} under={true} initializedValue={trade2State}/>
                    <TextInput title={'Zip:'} setTo={setTrade2Zip} complete={trade2ZipComplete} charCount={5} required={true} active={TradeReferencesInformationActive} under={true} initializedValue={trade2Zip}/>
                    <TextInput title={'Country:'} setTo={setTrade2Country} complete={trade2CountryComplete} charCount={2} required={false} active={TradeReferencesInformationActive} under={true} initializedValue={trade2Country}/>
                    
                </div>
                }

                <div className='External-Form-Spacer'>

                </div>

                {   secondaryReference && !thirdReference && 
                    <button onClick={() => setThirdReference(true)} className='External-Customer-Contact-Button Button-Add'>Add Third Reference</button>
                }
                {   thirdReference && secondaryReference &&
                   <button onClick={() => setThirdReference(false)} className='External-Customer-Contact-Button Button-Remove'>Remove Third Reference</button>
                }


                { thirdReference && secondaryReference &&
                <div className='External-Trade-Group'> 
                <TextInput title={'Company Name:'} setTo={setCompanyName3} complete={companyName3Complete} width={340} required={true} active={TradeReferencesInformationActive} initializedValue={companyName3}/>
                <TextInput title={'Contact First Name:'} setTo={setContactFirstName3} complete={contactTradeName3Complete} width={170} required={true} active={TradeReferencesInformationActive} initializedValue={contactFirstName3}/>
                <TextInput title={'Contact Last Name:'} setTo={setContactLastName3} complete={contactTradeName3Complete} width={170} required={true} active={TradeReferencesInformationActive} initializedValue={contactLastName3}/>
                <TextInput title={'Title:'} setTo={setTradeTitle3} complete={tradeTitle3Complete} width={170} required={true} active={TradeReferencesInformationActive} initializedValue={tradeTitle3}/>
                <TextInput title={'Email Address:'} setTo={setTradeEmail3} complete={tradeEmail3Complete} width={280} required={true} active={TradeReferencesInformationActive} initializedValue={tradeEmail3}/>
                <TextInput title={'Phone No:'} setTo={setTradePhone3} complete={tradePhone3Complete} charCount={10} required={true} active={TradeReferencesInformationActive} initializedValue={tradePhone3}/>
                <TextInput title={'Extension:'} setTo={setTradeExtension3}  charCount={6} required={false} active={TradeReferencesInformationActive} initializedValue={tradeExtension3}/>
               
                <div className='External-Form-Spacer'>

                </div>

                <div className='External-Form-Input-Title'>
                Contact Home Address:
                </div>

                <TextInput title={'Street:'} setTo={setTrade3Street}complete={trade3StreetComplete} width={220} required={true} active={TradeReferencesInformationActive} under={true} initializedValue={trade3Street}/>
                <TextInput title={'Street2:'} setTo={setTrade3Street2} width={120} required={false} active={TradeReferencesInformationActive} under={true} initializedValue={trade3Street2}/>
                <TextInput title={'City:'} setTo={setTrade3City} complete={trade3CityComplete} width={140} required={true} active={TradeReferencesInformationActive} under={true} initializedValue={trade3City}/>
                <TextInput title={'State:'} setTo={setTrade3State} complete={trade3StateComplete} charCount={2} width={40} required={true} active={TradeReferencesInformationActive} under={true} initializedValue={trade3State}/>
                <TextInput title={'Zip:'} setTo={setTrade3Zip} complete={trade3ZipComplete} charCount={5} required={true} active={TradeReferencesInformationActive} under={true} initializedValue={trade3Zip}/>
                <TextInput title={'Country:'} setTo={setTrade3Country} complete={trade3CountryComplete} charCount={2} required={false} active={TradeReferencesInformationActive} under={true} initializedValue={trade3Country}/>
                
                </div>
                }
            </div>
        }
        </>
    )
};


const ConditionsAndAuthorizations = ({ConditionsAndAuthorizationsActive,setNameSign,nameSign,nameSignComplete,setTitleSign,titleSign,titleSignComplete,
    setSignature,signatureComplete,setDate,date,dateComplete,setSecondarySignature,secondarySignature,setNameSign2,nameSign2,nameSign2Complete,setTitleSign2,titleSign2,titleSign2Complete,
    setSignature2,signature2Complete,setDate2,date2,date2Complete}) => {
        const conditionString = 'We certify that we are authorized to provide this information, and that the information on this form is correct to the best of our knowledge. We affirm financial responsibility, and it is understood that payment terms are net thirty (30) days and that any balances not paid within these terms shall be subject to a late charge. We agree to pay a monthly finance charge of 1.50% per month or 18% annually on all balances more than thirty (30) days past due. We acknowledge that any invoice more than sixty (60) days past due shall be sent to a collection agency. We agree to pay all costs of collection and litigation on this account, and by signing below, we acknowledge and agree that any legal proceeding or dispute related to this form or the relationship between the parties named herein will be governed by and interpreted according to the laws of the State of Alabama. We agree that all decisions with respect to the extension or continuation of business shall be in the sole discretion of the Seller.'
        return(
           <>
           {
            <div className='External-Conditions-Section'>
                
                <div className='External-Conditions-Title'>
                    Conditions and Authorizations
                </div>

                <div className='External-Conditions-Text'>
                    {conditionString}
                </div>
            
            <div className='External-Conditions-Informmation'>
            <TextInput title={`Name:`} setTo={setNameSign} complete={nameSignComplete} width={220} required={true} initializedValue={nameSign}/>
            <TextInput title={`Title:`} setTo={setTitleSign} complete={titleSignComplete} width={170} required={true} initializedValue={titleSign}/>
            <SignatureInput title={`Signature:`} setTo={setSignature} complete={signatureComplete} width={220}/>
            <TextInput title={`Date:`} setTo={setDate} complete={dateComplete} width={50} required={true} initializedValue={date}/>

                {   !secondarySignature && 
                    <button onClick={() => setSecondarySignature(true)} className='External-Customer-Trade-Reference-Button Button-Add'>Add Second Signaturee</button>
                }
                {   secondarySignature && 
                   <button onClick={() => setSecondarySignature(false)} className='External-Customer-Trade-Reference-Button Button-Remove'>Remove Second Signature</button>
                }

            { secondarySignature && 
            <div className='External-Conditions-Informmation'>
                <TextInput title={`Name:`} setTo={setNameSign2} complete={nameSign2Complete} width={220} required={true} initializedValue={nameSign2}/>
                <TextInput title={`Title:`} setTo={setTitleSign2} complete={titleSign2Complete} width={170} required={true} initializedValue={titleSign2}/>
                <SignatureInput title={'Signature:'} setTo={setSignature2} complete={signature2Complete} width={220}/>
                <TextInput title={`Date:`} setTo={setDate2} complete={date2Complete} width={50} required={true} initializedValue={date2}/>
            </div>
            }
            </div>

               
                
            </div>
           }
           </>
        )
    };
    
// FORM SUBMISSION - - - - - - - -
const FormSubmissionWindow = ({voidedCheckFileId,voidedCheckActive,taxCertId,taxCertActive,w9Id,intWireActive,intWireId,creditReferenceSheetActive,creditReferenceSheetId,finalizedSubmitting}) => {
    // FILES SECTION HELPER FUNCTION
    const FileSection = ({loading, title, seperator=false}) => {
        return(
            <div className='External-Form-Submission-Section'>
                <div className='External-Form-Submission-File-Group'>
                    <div className='External-Form-Submission-Status'>
                        {   loading &&
                            <LoadingSpinner size={60}/>
                        }     
                        {   !loading &&
                            <HiBadgeCheck className='External-Form-Accepted' />
                        }
                    </div>
                    <div className='External-Form-Submission-Title'>
                        {title}
                    </div>
                </div>
                {   seperator &&
                    <FaDotCircle className='External-Form-Submission-Seperator'/>
                }
            </div>
        )
    };
    
    return(
        <div className='External-Form-Submission-Window'>
            <FileSection loading={!w9Id} title={'Submitting W9'} seperator={true}/>

            {   voidedCheckActive &&
                <FileSection loading={!voidedCheckFileId} title={'Submitting Voided Check'} seperator={true}/>
            }
            {   taxCertActive &&
                <FileSection loading={!taxCertId} title={'Submitting Tax Certificate'} seperator={true}/>
            }
            {   intWireActive &&
                <FileSection loading={!intWireId} title={'Submitting International Wire Instructions'} seperator={true}/>
            }
            {   creditReferenceSheetActive &&
                <FileSection loading={!creditReferenceSheetId} title={'Submitting International Wire Instructions'} seperator={true}/>
            }
            

            <FileSection loading={!finalizedSubmitting} title={'Submitting Vendor Record'}/>

            {   finalizedSubmitting &&
                <div className='External-Form-Submission-Success'>
                    We have received your information. Thank you!
                </div>
            }

        </div>
    )
};

// PRIMARY FUNCTION - - - - - - - - -
const NewCustomer = () => {
    // DEFAULTS
    const formEmail = 'For questions or support, please email: ACH@cornerstoneinc.com';
    const businessTypeOptions = ['Corporation', 'Partnership' , 'Sole Proprietorship', 'Limited Liability Company (LLC)', 'Government'];
    const paymentOptions = ['Credit Card (5K or Less ONLY)', 'ACH', 'International Wire'];
    const taxExemptOptions = ['Yes (Provide Certificate)', 'No'];
   // const w9Options = ['Yes', 'No'];

     // CUSTOMER INFORMATION STATE
     const [ customerInformationActive, setcustomerInformationActive ] = useState(false);
     const [ customerInformationComplete, setcustomerInformationComplete ] = useState(false);
     const [ fedEinSSn, setFedEinSSn ] = useState('');
     const [ fedEinSSnComplete, setFedEinSSnComplete ] = useState(false);
     const [ website, setWebsite ] = useState('');
     const [ websiteComplete, setwebsiteComplete ] = useState(false);
     const [ dunsNo, setDunsNo ] = useState('');
     const [ dunsNoComplete, setdunsNoComplete ] = useState(false);
     const [ legalName, setLegalName ] = useState('');
     const [ legalNameComplete, setLegalNameComplete ] = useState(false);
     const [ tradeNameDBA, setTradeNameDBA ] = useState('');
     const [ tradeNameDBAComplete, setTradeNameDBAComplete ] = useState(false);
     const [ street, setStreet ] = useState('');
     const [ street2, setStreet2 ] = useState('');
     const [ streetComplete, setStreetComplete ] = useState(false);
     const [ city, setCity ] = useState('');
     const [ cityComplete, setCityComplete ] = useState(false);
     const [ state, setState ] = useState('');
     const [ stateComplete, setStateComplete ] = useState(false);
     const [ zip, setZip ] = useState('');
     const [ zipComplete, setZipComplete ] = useState(false);
     const [ country, setCountry ] = useState('');
     const [ shippingStreet, setShippingStreet ] = useState('');
     const [ shippingStreet2, setShippingStreet2 ] = useState('');
     const [ shippingStreetComplete, setShippingStreetComplete ] = useState(false);
     const [ shippingCity, setShippingCity ] = useState('');
     const [ shippingCityComplete, setShippingCityComplete ] = useState(false);
     const [ shippingState, setShippingState ] = useState('');
     const [ shippingStateComplete, setShippingStateComplete ] = useState(false);
     const [ shippingZip, setShippingZip ] = useState('');
     const [ shippingZipComplete, setShippingZipComplete ] = useState(false);
     const [ shippingCountry, setShippingCountry ] = useState('');
     const [ primaryContactFirstName, setPrimaryContactFirstName ] = useState('');
     const [ primaryContactLastName, setPrimaryContactLastName ] = useState('');
     const [ primaryContactNameComplete, setPrimaryContactNameComplete ] = useState(false);
     const [ primaryContactNumber, setPrimaryContactNumber ] = useState('');
     const [ primaryContactNumberComplete, setPrimaryContactNumberComplete ] = useState(false);
     const [ primaryExtension, setPrimaryExtension ] = useState('');
     const [ primaryTitle, setPrimaryTitle ] = useState('');
     const [ primaryTitleComplete, setPrimaryTitleComplete ] = useState(false);
     const [ primaryContactEmail, setPrimaryContactEmail ] = useState('');
     const [ primaryContactEmailComplete, setPrimaryContactEmailComplete ] = useState(false);
     const [ primaryStreet, setPrimaryStreet ] = useState('');
     const [ primaryStreetComplete, setPrimaryStreetComplete ] = useState(false);
     const [ primaryStreet2, setPrimaryStreet2 ] = useState('');
     const [ primaryCity, setPrimaryCity ] = useState('');
     const [ primaryCityComplete, setPrimaryCityComplete ] = useState(false);
     const [ primaryState, setPrimaryState ] = useState('');
     const [ primaryStateComplete, setPrimaryStateComplete ] = useState(false);
     const [ primaryZip, setPrimaryZip ] = useState('');
     const [ primaryZipComplete, setPrimaryZipComplete ] = useState(false);
     const [ primaryCountry, setPrimaryCountry ] = useState('');

     const [ taxExemptCert, setTaxExemptCert ] = useState('');
     const [ taxExemptCertComplete, setTaxExemptCertComplete ] = useState(false);
     const [ contactFirstNameAP, setContactFirstNameAP ] = useState('');
     const [ contactLastNameAP, setContactLastNameAP ] = useState('');
     const [ contactNameAPComplete, setContactNameAPComplete ] = useState(false);
     const [ phoneAP, setPhoneAP ] = useState('');
     const [ phoneAPComplete, setPhoneAPComplete ] = useState(false);
     const [ contactEmailAP, setContactEmailAP ] = useState('');
     const [ contactEmailAPComplete, setContactEmailAPComplete ] = useState(false);
     const [ paymentMethod, setPaymentMethod ] = useState('');
     const [ paymentMethodComplete, setPaymentMethodComplete ] = useState(false);
     const [ businessTypeSelection , setBusinessTypeSelection ] = useState('');
     const [ businessTypeSelectionComplete, setBusinessTypeSelectionComplete ] = useState(false);
     const [ stateOfInc, setStateOfInc ] = useState('');
     const [ stateOfIncComplete, setStateOfIncComplete ] = useState(false);

    //Required Files State
    const [ taxCertActive, setTaxCertActive ] = useState(false);
    const [ taxCert, setTaxCert ] = useState();
    const [ taxCertId, setTaxCertId ] = useState();
    const [ taxCertError, setTaxCertError ] = useState('');

    const [ w9Active, setW9Active ] = useState(true);
    const [ finishedW9, setFinishedW9 ] = useState();
    const [ w9, setW9 ] = useState('');
    const [ w9Complete, setW9Complete ] = useState(false);
    const [ w9Id, setW9Id ] = useState();
    const [ w9Error, setW9Error ] = useState('');
    const [ w9Required, setW9Required ] = useState(false);
    const [ intWireActive, setIntWireActive ] = useState(false);
    const [ intWire, setIntWire ] = useState();
    const [ intWireId, setIntWireId ] = useState();
    const [ intWireError, setIntWireError ] = useState('');
    
    const [ voidedCheckActive, setVoidedCheckActive ] = useState(false);
    const [ voidedCheckFile, setVoidedCheckFile ] = useState();
    const [ voidedCheckFileId, setVoidedCheckFileId ] = useState();
    const [ voidedCheckError, setVoidedCheckError ] = useState('');
    const [ filesRequired, setFilesRequired ] = useState(false);

    const [ creditReferenceSheetActive, setCreditReferenceSheetActive ] = useState(false);
    const [ creditReferenceSheet, setCreditReferenceSheet ] = useState();
    const [ creditReferenceSheetId, setCreditReferenceSheetId ] = useState();
    const [ creditReferenceSheetError, setCreditReferenceSheetError ] = useState('');
    

   //Credit Card State
    const [ creditCardActive, setCreditCardActive ] = useState(false);
    const [ nameOnCard, setNameOnCard ] = useState('');
    const [ nameOnCardComplete, setNameOnCardComplete ] = useState(false);
    const [ cardNumber, setCardNumber ] = useState('');
    const [ cardNumberComplete, setCardNumberComplete ] = useState(false);
    const [ expDate, setExpDate ] = useState('');
    const [ expDateComplete, setExpDateComplete ] = useState(false);
    const [ cvv, setCvv ] = useState('');
    const [ cvvComplete, setCvvComplete ] = useState(false);
    const [ cardZip, setCardZip ] = useState('');
    const [ cardZipComplete, setCardZipComplete ] = useState(false);

    //ACH State
    const [ achShown, setAchShown ] = useState(false);
    const [ achActive, setAchActive ] = useState(false);
    const [ achComplete, setAchComplete ] = useState(false);
    const [ bankName, setBankName ] = useState('');
    const [ bankNameComplete, setBankNameComplete ] = useState(false);
    const [ bankContactFirstName, setBankContactFirstName ] = useState('');
    const [ bankContactLastName, setBankContactLastName ] = useState('');
    const [ bankContactNameComplete, setBankContactNameComplete ] = useState(false);
    const [ bankContactTitle, setBankContactTitle ] = useState('');
    const [ bankContactTitleComplete, setBankContactTitleComplete ] = useState(false);
    const [ bankStreet, setBankStreet ] = useState('');
    const [ bankStreet2, setBankStreet2 ] = useState('');
    const [ bankStreetComplete, setBankStreetComplete ] = useState(false);
    const [ bankCity, setBankCity ] = useState('');
    const [ bankCityComplete, setBankCityComplete ] = useState('');
    const [ bankState, setBankState ] = useState('');
    const [ bankStateComplete, setBankStateComplete ] = useState(false);
    const [ bankZip, setBankZip ] = useState('');
    const [ bankZipComplete, setBankZipComplete ] = useState(false);
    const [ bankCountry, setBankCountry ] = useState('');
    const [ bankCountryComplete, setBankCountryComplete ] = useState(false);
    const [ bankTelephone, setBankTelephone ] = useState('');
    const [ bankTelephoneComplete, setBankTelephoneComplete ] = useState(false);
    const [ bankExtension, setBankExtension ] = useState('');
    const [ bankContactEmail, setBankContactEmail ] = useState('');
    const [ bankContactEmailComplete, setBankContactEmailComplete ] = useState(false);
    const [ bankAccountName, setBankAccountName ] = useState('');
    const [ bankAccountNameComplete, setBankAccountNameComplete ] = useState(false);
    const [ bankAccountNumber, setBankAccountNumber ] = useState('');
    const [ bankAccountNumberComplete, setBankAccountNumberComplete ] = useState(false);
    const [ bankRoutingNumber, setBankRoutingNumber ] = useState('');
    const [ bankRoutingNumberComplete, setBankRoutingNumberComplete ] = useState(false);
    const [ bankSwiftCode, setBankSwiftCode ] = useState('');
    const [ bankSwiftCodeComplete, setBankSwiftCodeComplete ] = useState(false);
    const [ bankAccountSelection, setBankAccountSelection ] = useState('Checking');
    const [ bankAccountSelectionComplete, setBankAccountSelectionComplete ] = useState(false);

/*
     // INTERNATIONAL WIRE STATE
    const [ intWireShown, setIntWireShown ] = useState(false);
    const [ intWireActive, setIntWireActive ] = useState(false);
    const [ intWire, setIntWire ] = useState();
    const [ intWireId, setIntWireId ] = useState();
    const [ intWireError, setIntWireError ] = useState('');

     W9 STATE
    const [ w9Shown, setW9Shown ] = useState(false);
    const [ w9Active, setW9Active ] = useState(false);
    const [ finishedW9, setFinishedW9 ] = useState();
    const [ w9Id, setW9Id ] = useState();
    const [ w9Error, setW9Error ] = useState('');
    */

    // Owner/s Or AUTHORIZED OFFICERS STATE
    const [ OwnersOrOfficersInformationActive, setOwnersOrInformationActive ] = useState('');
    const [ firstName1, setFirstName1 ] = useState('');
    const [ lastName1, setLastName1 ] = useState('');
    const [ ownerName1Complete, setOwnerName1Complete ] = useState(false);
    const [ title, setTitle ] = useState('');
    const [ titleComplete, setTitleComplete ] = useState(false);
    const [ phone, setPhone ] = useState('');
    const [ phoneComplete, setPhoneComplete ] = useState(false);
    const [ extension, setExtension ] = useState('');
    const [ ownerStreet, setOwnerStreet ] = useState('');
    const [ ownerStreetComplete, setOwnerStreetComplete ] = useState(false);
    const [ ownerStreet2, setOwnerStreet2 ] = useState('');
    const [ ownerCity, setOwnerCity ] = useState('');
    const [ ownerCityComplete, setOwnerCityComplete ] = useState(false);
    const [ ownerState, setOwnerState ] = useState('');
    const [ ownerStateComplete, setOwnerStateComplete ] = useState(false);
    const [ ownerZip, setOwnerZip ] = useState('');
    const [ ownerZipComplete, setOwnerZipComplete ] = useState('');
    const [ ownerCountry, setOwnerCountry ] = useState(false);
    const [ emailAddress, setEmailAddress ] = useState('');
    const [ emailAddressComplete, setEmailAddressComplete ] = useState(false);
    const [ secondaryOwner, setSecondaryOwner ] = useState(false);
    const [ firstName2, setFirstName2 ] = useState('');
    const [ lastName2, setLastName2 ] = useState('');
    const [ ownerName2Complete, setOwnerName2Complete ] = useState(false);
    const [ title2, setTitle2 ] = useState('');
    const [ title2Complete, setTitle2Complete ] = useState(false);
    const [ phone2, setPhone2 ] = useState('');
    const [ phone2Complete, setPhone2Complete ] = useState(false);
    const [ extension2, setExtension2 ] = useState('');
    const [ owner2Street, setOwner2Street ] = useState('');
    const [ owner2StreetComplete, setOwner2StreetComplete ] = useState(false);
    const [ owner2Street2, setOwner2Street2 ] = useState('');
    const [ owner2City, setOwner2City ] = useState('');
    const [ owner2CityComplete, setOwner2CityComplete ] = useState(false);
    const [ owner2State, setOwner2State ] = useState('');
    const [ owner2StateComplete, setOwner2StateComplete ] = useState(false);
    const [ owner2Zip, setOwner2Zip ] = useState('');
    const [ owner2ZipComplete, setOwner2ZipComplete ] = useState('');
    const [ owner2Country, setOwner2Country ] = useState(false);
    const [ emailAddress2, setEmailAddress2 ] = useState('');
    const [ emailAddress2Complete, setEmailAddress2Complete ] = useState(false);


    /*
    //Bank Reference State
    const [ BankReferenceInformationActive, setBankReferenceInformationActive ] = useState(false);
    const [ bankNameReference, setBankNameReference ] = useState('');
    const [ bankNameReferenceComplete, setBankNameReferenceComplete ] = useState(false);
    const [ bankContactName, setBankContactName] = useState('');
    const [ bankContactNameReferenceComplete, setBankContactNameReferenceComplete ] = useState(false);
    const [ bankAddress, setBankAddress ] = useState('');
    const [ bankAddressComplete, setBankAddressComplete ] = useState(false);
    const [ bankPhone, setBankPhone ] = useState('');
    const [ bankPhoneComplete, setBankPhoneComplete ] = useState(false);
    const [ bankEmail, setBankEmail ] = useState('');
    const [ bankEmailComplete, setBankEmailComplete ] = useState(false);
    const [ nameOnAccount, setNameOnAccount ] = useState('');
    const [ nameOnAccountComplete, setNameOnAccountComplete ] = useState(false);
*/


    //Trade Reference State
    const [ TradeReferencesInformationActive, setTradeReferencesInformationActive ] = useState('');
    const [ companyName, setCompanyName ] = useState('');
    const [ companyNameComplete, setCompanyNameComplete ] = useState(false);
    const [ contactFirstName, setContactFirstName ] = useState('');
    const [ contactLastName, setContactLastName ] = useState('');
    const [ contactTradeNameComplete, setContactTradeNameComplete ] = useState(false);
    const [ tradeTitle, setTradeTitle ] = useState('');
    const [ tradeTitleComplete, setTradeTitleComplete ] = useState(false);
    const [ tradeEmail, setTradeEmail ] = useState('');
    const [ tradeEmailComplete, setTradeEmailComplete ] = useState(false);
    const [ tradePhone, setTradePhone ]  = useState('');
    const [ tradePhoneComplete, setTradePhoneComplete ] = useState(false);
    const [ tradeExtension, setTradeExtension ]  = useState('');
    const [ tradeStreet, setTradeStreet ] = useState('');
    const [ tradeStreetComplete, setTradeStreetComplete ] = useState(false);
    const [ tradeStreet2, setTradeStreet2 ] = useState('');
    const [ tradeCity, setTradeCity ] = useState('');
    const [ tradeCityComplete, setTradeCityComplete ] = useState(false);
    const [ tradeState, setTradeState ] = useState('');
    const [ tradeStateComplete, setTradeStateComplete ] = useState(false);
    const [ tradeZip, setTradeZip ] = useState('');
    const [ tradeZipComplete, setTradeZipComplete ] = useState(false);
    const [ tradeCountry, setTradeCountry ] = useState('');
    const [ tradeCountryComplete, setTradeCountryComplete ] = useState(false);
    const [ secondaryReference, setSecondaryReference ] = useState(false);
    const [ companyName2, setCompanyName2 ] = useState('');
    const [ companyName2Complete, setCompanyName2Complete ] = useState(false);
    const [ contactFirstName2, setContactFirstName2 ] = useState('');
    const [ contactLastName2, setContactLastName2 ] = useState('');
    const [ contactTradeName2Complete, setContactTradeName2Complete ] = useState(false);
    const [ tradeTitle2, setTradeTitle2 ] = useState('');
    const [ tradeTitle2Complete, setTradeTitle2Complete ] = useState(false);
    const [ tradeEmail2, setTradeEmail2 ] = useState('');
    const [ tradeEmail2Complete, setTradeEmail2Complete ] = useState(false);
    const [ tradePhone2, setTradePhone2 ]  = useState('');
    const [ tradePhone2Complete, setTradePhone2Complete ] = useState(false);
    const [ tradeExtension2, setTradeExtension2 ]  = useState('');
    const [ trade2Street, setTrade2Street ] = useState('');
    const [ trade2StreetComplete, setTrade2StreetComplete ] = useState(false);
    const [ trade2Street2, setTrade2Street2 ] = useState('');
    const [ trade2City, setTrade2City ] = useState('');
    const [ trade2CityComplete, setTrade2CityComplete ] = useState(false);
    const [ trade2State, setTrade2State ] = useState('');
    const [ trade2StateComplete, setTrade2StateComplete ] = useState(false);
    const [ trade2Zip, setTrade2Zip ] = useState('');
    const [ trade2ZipComplete, setTrade2ZipComplete ] = useState('');
    const [ trade2Country, setTrade2Country ] = useState('');
    const [ trade2CountryComplete, setTrade2CountryComplete ] = useState(false);
    const [ thirdReference, setThirdReference ] = useState(false);
    const [ companyName3, setCompanyName3 ] = useState('');
    const [ companyName3Complete, setCompanyName3Complete ] = useState(false);
    const [ contactFirstName3, setContactFirstName3 ] = useState('');
    const [ contactLastName3, setContactLastName3 ] = useState('');
    const [ contactTradeName3Complete, setContactTradeName3Complete ] = useState(false);
    const [ tradeTitle3, setTradeTitle3 ] = useState('');
    const [ tradeTitle3Complete, setTradeTitle3Complete ] = useState(false);
    const [ tradeEmail3, setTradeEmail3 ] = useState('');
    const [ tradeEmail3Complete, setTradeEmail3Complete ] = useState(false);
    const [ tradePhone3, setTradePhone3 ]  = useState('');
    const [ tradePhone3Complete, setTradePhone3Complete ] = useState(false);
    const [ tradeExtension3, setTradeExtension3 ]  = useState('');
    const [ trade3Street, setTrade3Street ] = useState('');
    const [ trade3StreetComplete, setTrade3StreetComplete ] = useState(false);
    const [ trade3Street2, setTrade3Street2 ] = useState('');
    const [ trade3City, setTrade3City ] = useState('');
    const [ trade3CityComplete, setTrade3CityComplete ] = useState(false);
    const [ trade3State, setTrade3State ] = useState('');
    const [ trade3StateComplete, setTrade3StateComplete ] = useState(false);
    const [ trade3Zip, setTrade3Zip ] = useState('');
    const [ trade3ZipComplete, setTrade3ZipComplete ] = useState('');
    const [ trade3Country, setTrade3Country ] = useState('');
    const [ trade3CountryComplete, setTrade3CountryComplete ] = useState(false);

    //Conditions State
    const [ conditionsComplete, setConditionsComplete ] = useState(false);
    const [ nameSign, setNameSign ] = useState('');
    const [ nameSignComplete, setNameSignComplete ] = useState(false);
    const [ titleSign, setTitleSign ] = useState('');
    const [ titleSignComplete, setTitleSignComplete ] = useState(false);
    const [ signature, setSignature ] = useState('');
    const [ signatureComplete, setSignatureComplete ] = useState(false);
    const [ date, setDate ]  = useState('');
    const [ dateComplete, setDateComplete ] = useState(false);
    const [ secondarySignature, setSecondarySignature ] = useState(false);
    const [ nameSign2, setNameSign2 ] = useState('');
    const [ nameSign2Complete, setNameSign2Complete ] = useState(false);
    const [ titleSign2, setTitleSign2 ] = useState('');
    const [ titleSign2Complete, setTitleSign2Complete ] = useState(false);
    const [ signature2, setSignature2 ] = useState('');
    const [ signature2Complete, setSignature2Complete ] = useState(false);
    const [ date2, setDate2 ]  = useState('');
    const [ date2Complete, setDate2Complete ] = useState(false);



    // Submitting State
    const [ formComplete, setFormComplete] = useState(false);
    const [ submitting, setSubmitting] =useState(false);
    const [ finalizedSubmitting, setFinalizedSubmitted ] = useState(false);
    const [ rejectionRecord, setRejectionRecord ] = useState();
    const [ formSubmissionValid, setFormSubmissionValid] = useState();




    useEffect(() => {
        if(w9Required) setFilesRequired(true);
    },[w9Required]);

    useEffect(() => {    // TRIGGER ACH & INT WIRE WINDOW SHOW
        if(!rejectionRecord){
            if(paymentMethod === 'ACH'){
                setAchActive(true);
                setAchShown(true);
                setIntWireActive(false);
                //setIntWireShown(false);
                setVoidedCheckActive(true);
                setCreditCardActive(false);
            }

             else if(paymentMethod === 'International Wire'){
                setIntWireActive(true);
               // setIntWireShown(true);
               setCreditCardActive(false);
                setAchActive(true);
                setAchShown(true);
                setCreditCardActive(false);
               // setVoidedCheckShown(false);
                setVoidedCheckActive(false);
                
            }

            else if(paymentMethod === 'Credit Card (5K or Less ONLY)'){
                setCreditCardActive(true);
                setAchActive(false);
                setVoidedCheckActive(false);
                setIntWireActive(false);

            }
            else{
                setCreditCardActive(false);
                setAchActive(false);
                setAchShown(false);
                //setVoidedCheckShown(false);
                setVoidedCheckActive(false);
                setIntWireActive(false);
                //setIntWireShown(false);
            };
        };
    },[paymentMethod]);

    useEffect(() => {    // TRIGGER Tax Cert WINDOW SHOW
        if(!rejectionRecord){

             if(taxExemptCert === 'Yes (Provide Certificate)'){
                setTaxCertActive(true);
                //setTaxCertShown(true);
            }
            else{
                setTaxCertActive(false);
                //setTaxCertShown(false);
            };
        };
    },[taxExemptCert]);    

    useEffect(() => {    // TRIGGER Tax Cert WINDOW SHOW
        if(!rejectionRecord){

             if(businessTypeSelection !== 'Government'){
                setCreditReferenceSheetActive(true);
            }
            else{
                setCreditReferenceSheetActive(false);
            };
        };
    },[creditReferenceSheet]); 



    const checkFormFields = () => {
        let complete = true;

        if(fedEinSSn.length === 9){
            setFedEinSSnComplete(true);
        }
        else{
            setFedEinSSnComplete(false)
            complete = false;
        };

        if(dunsNo.length === 9) {
            setdunsNoComplete(true);
        }
        else{
            setdunsNoComplete(false);
            complete = false;
        };

        if(legalName.length > 3){
            setLegalNameComplete(true); 
        } 
        else{
            setLegalNameComplete(false);
            complete = false;

        };   
        
        if(tradeNameDBA.length > 2) {
            setTradeNameDBAComplete(true);
        }
        else{
            setTradeNameDBAComplete(false)
            complete = false;
        };


        if(street.length > 2) {
            setStreetComplete(true);
        }
        else{
            setStreetComplete(false)
            complete = false;
        };


        
        if(shippingStreet.length > 2) {
            setShippingStreetComplete(true);
        }
        else{
            setShippingStreetComplete(false)
            complete = false;
        };
        
        if(city.length > 2) {
            setCityComplete(true);
        }
        else{
            setCityComplete(false)
            complete = false;
        };
        
        if(shippingCity.length > 2) {
            setShippingCityComplete(true);
        }
        else{
            setShippingCityComplete(false)
            complete = false;
        };
        
        if(state.length > 1) {
            setStateComplete(true);
        }
        else{
            setStateComplete(false)
            complete = false;
        };
        
        if(shippingState.length > 1) {
            setShippingStateComplete(true);
        }
        else{
            setShippingStateComplete(false)
            complete = false;
        };
        
        if(zip.length === 5){
            setZipComplete(true);
        }
        else{
            setZipComplete(false)
            complete = false;
        };
        
        if(shippingZip.length === 5){
            setShippingZipComplete(true);
        }
        else{
            setShippingZipComplete(false)
            complete = false;
        };
        
        if(primaryContactFirstName.length > 2 && primaryContactLastName.length > 2) setPrimaryContactNameComplete(true);
        else{
            setPrimaryContactNameComplete(false);
            complete = false;
        };
        
        if(primaryContactNumber.length === 10){
            setPrimaryContactNumberComplete(true);
        }
        else{
            setPrimaryContactNumberComplete(false);
            complete = false;
        };
        
        if(primaryTitle.length > 2){
            setPrimaryTitleComplete(true);
        }
        else{
            setPrimaryTitleComplete(false);
            complete = false;
        };
        
        if(primaryStreet.length > 2) {
            setPrimaryStreetComplete(true);
        }
        else{
            setPrimaryStreetComplete(false)
            complete = false;
        };
        
        if(primaryCity.length > 2) {
            setPrimaryCityComplete(true);
        }
        else{
            setPrimaryCityComplete(false)
            complete = false;
        };
        
        if(primaryState.length > 1) {
            setPrimaryStateComplete(true);
        }
        else{
            setPrimaryStateComplete(false)
            complete = false;
        };

        if(primaryZip.length === 5) {
            setPrimaryZipComplete(true);
        }
        else{
            setPrimaryZipComplete(false)
            complete = false;
        };

        
        if(checkEmail(primaryContactEmail)) setPrimaryContactEmailComplete(true);
        else{
            setPrimaryContactEmailComplete(false);
            complete = false;
        };


        if(contactFirstNameAP.length > 2 && contactLastNameAP.length > 2){
            setContactNameAPComplete(true);
        }
        else{
            setContactNameAPComplete(false);
            complete = false;
        };

        if(phoneAP.length === 10){
            setPhoneAPComplete(true);
        }
        else{
            setPhoneAPComplete(false);
            complete = false;
        };

        if(checkEmail(contactEmailAP)) setContactEmailAPComplete(true);
        else{
            setContactEmailAPComplete(false);
            complete = false;
        };


        if(stateOfInc.length == 2){
            setStateOfIncComplete(true);
        }
        else{
            setStateOfIncComplete(false);
            complete = false;
        };

    
        if(firstName1.length > 2 && lastName1.length > 2){
            setOwnerName1Complete(true);   
        } 
        else{
            setOwnerName1Complete(false);
            complete = false;
        };

        if(title.length > 2){
            setTitleComplete(true);
        }
        else{
            setTitleComplete(false);
            complete = false;
        };

        if(phone.length === 10){
            setPhoneComplete(true);
        }
        else{
            setPhoneComplete(false);
            complete = false;
        };

        if(ownerStreet.length > 2) {
            setOwnerStreetComplete(true);
        }
        else{
            setOwnerStreetComplete(false)
            complete = false;
        };

        if(ownerCity.length > 2) {
            setOwnerCityComplete(true);
        }
        else{
            setOwnerCityComplete(false)
            complete = false;
        };

        if(ownerState.length > 1) {
            setOwnerStateComplete(true);
        }
        else{
            setOwnerStateComplete(false)
            complete = false;
        };

        if(ownerZip.length === 5) {
            setOwnerZipComplete(true);
        }
        else{
            setOwnerZipComplete(false)
            complete = false;
        };

        if(checkEmail(emailAddress)) setEmailAddressComplete(true);
        else{
            setEmailAddressComplete(false);
            complete = false;
        };

        if(secondaryOwner){
            if(firstName2.length > 2 && lastName2.length > 2) setOwnerName2Complete(true);
            else{
                setOwnerName2Complete(false);
                complete = false;
            };
    
            if(title2.length > 2){
                setTitle2Complete(true);
            }
            else{
                setTitle2Complete(false);
                complete = false;
            };

            if(phone2.length === 10){
                setPhone2Complete(true);
            }
            else{
                setPhone2Complete(false);
                complete = false;
            };
    
            if(owner2Street.length > 2) {
                setOwner2StreetComplete(true);
            }
            else{
                setOwner2StreetComplete(false)
                complete = false;
            };
    
            if(owner2City.length > 2) {
                setOwner2CityComplete(true);
            }
            else{
                setOwner2CityComplete(false)
                complete = false;
            };
    
            if(owner2State.length > 1) {
                setOwner2StateComplete(true);
            }
            else{
                setOwner2StateComplete(false)
                complete = false;
            };
    
            if(owner2Zip.length === 5) {
                setOwner2ZipComplete(true);
            }
            else{
                setOwner2ZipComplete(false)
                complete = false;
            };
    
            if(checkEmail(emailAddress2)) setEmailAddress2Complete(true);
            else{
                setEmailAddress2Complete(false);
                complete = false;
            };
        }
        

        if(companyName.length > 2){
            setCompanyNameComplete(true);
        }
        else{
            setCompanyNameComplete(false);
            complete = false;
        };

        if(contactFirstName.length > 2 && contactLastName.length > 2) setContactTradeNameComplete(true);
        else{
            setContactTradeNameComplete(false);
            complete = false;
        };

        if(tradeTitle.length > 2){
            setTradeTitleComplete(true);
        }
        else{
            setTradeTitleComplete(false);
            complete = false;
        }

        if(checkEmail(tradeEmail)) setTradeEmailComplete(true);
            else{
                setTradeEmailComplete(false);
                complete = false;
            };

        if(tradePhone.length === 10){
            setTradePhoneComplete(true);
        }
        else{
            setTradePhoneComplete(false);
            complete = false;
        };  

        if(tradeStreet.length > 3) {
            setTradeStreetComplete(true);
        }
        else{
            setTradeStreetComplete(false)
            complete = false;
        };

        if(tradeCity.length > 2) {
            setTradeCityComplete(true);
        }
        else{
            setTradeCityComplete(false)
            complete = false;
        };

        if(tradeState.length > 1) {
            setTradeStateComplete(true);
        }
        else{
            setTradeStateComplete(false)
            complete = false;
        };

        if(tradeZip.length === 5) {
            setTradeZipComplete(true);
        }
        else{
            setTradeZipComplete(false)
            complete = false;
        };
        
        if(secondaryReference){
            
            if(companyName2.length > 2){
                setCompanyName2Complete(true);
            }
            else{
                setCompanyName2Complete(false);
                complete = false;
            };
    
            if(contactFirstName2.length > 3 && contactLastName2.length > 3) setContactTradeName2Complete(true);
            else{
                setContactTradeName2Complete(false);
                complete = false;
            };

            if(tradeTitle2.length > 2){
                setTradeTitle2Complete(true);
            }
            else{
                setTradeTitle2Complete(false);
                complete = false;
            }
    
            if(checkEmail(tradeEmail2)) setTradeEmail2Complete(true);
                else{
                    setTradeEmail2Complete(false);
                    complete = false;
                };
    
            if(tradePhone2.length === 10){
                setTradePhone2Complete(true);
            }
            else{
                setTradePhone2Complete(false);
                complete = false;
            };

            if(trade2Street.length > 3) {
                setTrade2StreetComplete(true);
            }
            else{
                setTrade2StreetComplete(false)
                complete = false;
            };
    
            if(trade2City.length > 2) {
                setTrade2CityComplete(true);
            }
            else{
                setTrade2CityComplete(false)
                complete = false;
            };
    
            if(trade2State.length > 1) {
                setTrade2StateComplete(true);
            }
            else{
                setTrade2StateComplete(false)
                complete = false;
            };
    
            if(trade2Zip.length === 5) {
                setTrade2ZipComplete(true);
            }
            else{
                setTrade2ZipComplete(false)
                complete = false;
            };

        }
            if(thirdReference){

                if(companyName3.length > 2){
                    setCompanyName3Complete(true);
                }
                else{
                    setCompanyName3Complete(false);
                    complete = false;
                };
        
                if(contactFirstName3.length > 2 && contactLastName3.length > 2){
                    setContactTradeName3Complete(true);
                } 
                else{
                    setContactTradeName3Complete(false);
                    complete = false;
                };

                if(tradeTitle3.length > 2){
                    setTradeTitle3Complete(true);
                }
                else{
                    setTradeTitle3Complete(false);
                    complete = false;
                }
        
                if(checkEmail(tradeEmail3)) setTradeEmail3Complete(true);
                    else{
                        setTradeEmail3Complete(false);
                        complete = false;
                    };
        
                if(tradePhone3.length === 10){
                    setTradePhone3Complete(true);
                }
                else{
                    setTradePhone3Complete(false);
                    complete = false;
                };  

                if(trade3Street.length > 2) {
                    setTrade3StreetComplete(true);
                }
                else{
                    setTrade3StreetComplete(false)
                    complete = false;
                };
        
                if(trade3City.length > 2) {
                    setTrade3CityComplete(true);
                }
                else{
                    setTrade3CityComplete(false)
                    complete = false;
                };
        
                if(trade3State.length > 1) {
                    setTrade3StateComplete(true);
                }
                else{
                    setTrade3StateComplete(false)
                    complete = false;
                };
        
                if(trade3Zip.length === 5) {
                    setTrade3ZipComplete(true);
                }
                else{
                    setTrade3ZipComplete(false)
                    complete = false;
                };

            }

            if(paymentMethod === 'ACH'){
                if(bankName.length > 2) setBankNameComplete(true);
                else{
                    setBankNameComplete(false);
                    complete = false;
                };
        
                if(bankContactFirstName.length > 2 && bankContactLastName.length > 2) setBankContactNameComplete(true);
                else{
                    setBankContactNameComplete(false);
                    complete = false;
                };

                if(bankContactTitle.length > 2){
                    setBankContactTitleComplete(true);
                }
                else{
                    setBankContactTitleComplete(false);
                    complete = false;
                }
        
                if(bankStreet.length > 2) setBankStreetComplete(true);
                else{
                    setBankStreetComplete(false);
                    complete = false;
                };
        
                if(bankCity.length > 2) setBankCityComplete(true);
                else{
                    setBankCityComplete(false);
                    complete = false;
                };
        
                if(bankState.length > 1) setBankStateComplete(true);
                else{
                    setBankStateComplete(false);
                    complete = false;
                };
        
                if(bankZip.length === 5) setBankZipComplete(true);
                else{
                    setBankZipComplete(false);
                    complete = false;
                };
        
                if(bankZip.length === 5) setBankZipComplete(true);
                else{
                    setBankZipComplete(false);
                    complete = false;
                };
        
                if(bankTelephone.length === 10) setBankTelephoneComplete(true);
                else{
                    setBankTelephoneComplete(false);
                    complete = false;
                };
                
                if(checkEmail(bankContactEmail)) setBankContactEmailComplete(true);
                else{
                    setBankContactEmailComplete(false);
                    complete = false;
                };
        
                if(bankAccountName.length > 2) setBankAccountNameComplete(true);
                else{
                    setBankAccountNameComplete(false);
                    complete = false;
                };
        
                if(bankAccountNumber.length > 7) setBankAccountNumberComplete(true);
                else{
                    setBankAccountNumberComplete(false);
                    complete = false;
                };
        
                if(bankRoutingNumber.length === 9) setBankRoutingNumberComplete(true);
                else{
                    setBankRoutingNumberComplete(false);
                    complete = false;
                };
        
                if(bankAccountSelection && bankAccountSelection !== '') setBankAccountSelectionComplete(true);
                else{
                    setBankAccountSelectionComplete(false);
                    complete = false;
                };
            }

            

            if(paymentMethod === 'Credit Card (5K or Less ONLY)'){
                if(nameOnCard.length > 2) {
                    setNameOnCardComplete(true);
                }
                else{
                    setNameOnCardComplete(false);
                    complete = false;
                };
            

            if(cardNumber.length > 15){
                setCardNumberComplete(true);
            }
            else{
                setCardNumberComplete(false);
                complete = false;
            };

            if(expDate.length > 3){
                setExpDateComplete(true);
            }
            else{
                setExpDateComplete(false);
                complete = false;
            };

            if(cvv.length > 2){
                setCvvComplete(true);
            }
            else{
                setCvvComplete(false);
                complete = false;
            };

            if(cardZip.length === 5){
                setCardZipComplete(true);
            }
            else{
                setCardZipComplete(false);
                complete = false;
            };

        }

            

            if(signature.length > 2) setSignatureComplete(true);
            else{
                setSignatureComplete(false);
                complete = false;
            };
    
            if(nameSign.length > 2) setNameSignComplete(true);
            else{
                setNameSignComplete(false);
                complete = false;
            };

            if(titleSign.length > 2) {
                setTitleSignComplete(true);
            }
            else{
                setTitleSignComplete(false);
                complete = false;
            };

            if(date.length > 2) {
                setDateComplete(true);
            }
            else{
                setDateComplete(false);
                complete = false;
            };

            

            if(secondarySignature) {
                
                if(nameSign2.length > 2){
                    setNameSign2Complete(true);  
                } 
                else{
                    setNameSign2Complete(false);
                    complete = false;
                };
                
                if(titleSign2.length > 2) {
                    setTitleSign2Complete(true);
                }
                else{
                    setTitleSign2Complete(false);
                    complete = false;
                };

                if(signature2.length > 2) setSignature2Complete(true);
                else{
                    setSignature2Complete(false);
                    complete = false;
                };
                if(date2.length > 2){
                    setDate2Complete(true);
                }
                else{
                    setDate2Complete(false);
                    complete = false;
                };
            }
            
        return complete;
    };

    useEffect(() => {
        if(!rejectionRecord){
            setFormComplete(checkFormFields());
        };
    },[fedEinSSn,dunsNo,legalName,tradeNameDBA,street,street2,city,state,zip,shippingStreet,shippingCity,shippingState,shippingZip,
        primaryContactFirstName,primaryContactLastName,primaryContactNumber,primaryTitle,primaryContactEmail,primaryStreet,primaryCity,
        primaryState,primaryZip,contactFirstNameAP,contactLastNameAP,phoneAP,contactEmailAP,stateOfInc,firstName1,lastName1,title,phone,ownerStreet,ownerCity,
        ownerState,ownerZip,emailAddress,firstName2,lastName2,title2,phone2,owner2Street,owner2City,owner2State,owner2Zip,emailAddress2,
        companyName,contactFirstName,contactLastName,tradeTitle,tradeEmail,tradePhone,tradeStreet,tradeCity,tradeState,tradeZip,companyName2,contactFirstName2,
        contactLastName2,tradeTitle2,tradeEmail2,tradePhone2,trade2Street,trade2City,trade2State,trade2Zip,companyName3,contactFirstName3,contactLastName3,
        tradeTitle3,tradeEmail3,tradePhone3,trade3Street,trade3City,trade3State,trade3Zip,nameSign,titleSign,signature,date,nameSign2,titleSign2,signature2,date2,
        bankName,bankContactFirstName,bankContactLastName,bankContactTitle,bankStreet,bankCity,bankState,bankZip,bankTelephone,bankContactEmail,
        bankAccountName,bankAccountNumber,bankRoutingNumber,bankSwiftCode,bankAccountSelection,nameOnCard,cardNumber,expDate,cvv,cardZip
     ])



    

    const customerInformationProps = {
        setFedEinSSn:setFedEinSSn,
        fedEinSSn:fedEinSSn,
        fedEinSSnComplete,fedEinSSnComplete,
        setDunsNo:setDunsNo,
        dunsNo:dunsNo,
        dunsNoComplete:dunsNoComplete,
        setWebsite:setWebsite,
        website:website,
        websiteComplete:websiteComplete,
        setLegalName:setLegalName,
        legalName:legalName,
        legalNameComplete:legalNameComplete,
        setTradeNameDBA:setTradeNameDBA,
        tradeNameDBA:tradeNameDBA,
        tradeNameDBAComplete:tradeNameDBAComplete,
        setStreet:setStreet,
        street:street,
        street2:street2,
        setStreet2:setStreet2,
        streetComplete:streetComplete,
        setCity:setCity,
        city:city,
        cityComplete:cityComplete,
        setState:setState,
        state:state,
        stateComplete:stateComplete,
        setZip:setZip,
        zip:zip,
        zipComplete:zipComplete,
        setCountry:setCountry,
        country:country,
        setShippingStreet:setShippingStreet,
        shippingStreet:shippingStreet,
        shippingStreet2:shippingStreet2,
        setShippingStreet2:setShippingStreet2,
        shippingStreetComplete:shippingStreetComplete,
        setShippingCity:setShippingCity,
        shippingCity:shippingCity,
        shippingCityComplete:shippingCityComplete,
        setShippingState:setShippingState,
        shippingState:shippingState,
        shippingStateComplete:shippingStateComplete,
        setShippingZip:setShippingZip,
        shippingZip:shippingZip,
        shippingZipComplete:shippingZipComplete,
        setShippingCountry:setShippingCountry,
        shippingCountry:shippingCountry,
        setPrimaryContactFirstName:setPrimaryContactFirstName,
        primaryContactFirstName:primaryContactFirstName,
        setPrimaryContactLastName:setPrimaryContactLastName,
        primaryContactLastName:primaryContactLastName,
        primaryContactNameComplete:primaryContactNameComplete,
        setPrimaryContactNumber:setPrimaryContactNumber,
        primaryContactNumber:primaryContactNumber,
        primaryContactNumberComplete:primaryContactNumberComplete,
        setPrimaryExtension:setPrimaryExtension,
        primaryExtension:primaryExtension,
        setPrimaryTitle:setPrimaryTitle,
        primaryTitle:primaryTitle,
        primaryTitleComplete:primaryTitleComplete,
        setPrimaryContactEmail:setPrimaryContactEmail,
        primaryContactEmail:primaryContactEmail,
        primaryContactEmailComplete:primaryContactEmailComplete,
        setPrimaryStreet:setPrimaryStreet,
        primaryStreet:primaryStreet,
        primaryStreetComplete:primaryStreetComplete,
        setPrimaryStreet2:setPrimaryStreet2,
        primaryStreet2:primaryStreet2,
        setPrimaryCity:setPrimaryCity,
        primaryCity:primaryCity,
        primaryCityComplete:primaryCityComplete,
        setPrimaryState:setPrimaryState,
        primaryState:primaryState,
        primaryStateComplete:primaryStateComplete,
        setPrimaryZip:setPrimaryZip,
        primaryZip:primaryZip,
        primaryZipComplete:primaryZipComplete,
        setPrimaryCountry:setPrimaryCountry,
        primaryCountry:primaryCountry,
        taxExemptOptions:taxExemptOptions,
        setTaxExemptCert:setTaxExemptCert,
        taxExemptCert:taxExemptCert,
        taxExemptCertComplete:taxExemptCertComplete,
        contactFirstNameAP:contactFirstNameAP,
        setContactFirstNameAP:setContactFirstNameAP,
        contactLastNameAP:contactLastNameAP,
        setContactLastNameAP:setContactLastNameAP,
        contactNameAPComplete:contactNameAPComplete,
        setPhoneAP:setPhoneAP,
        phoneAP:phoneAP,
        phoneAPComplete:phoneAPComplete,
        setContactEmailAP:setContactEmailAP,
        contactEmailAP:contactEmailAP,
        contactEmailAPComplete:contactEmailAPComplete,
        paymentOptions:paymentOptions,
        setPaymentMethod:setPaymentMethod,
        paymentMethod:paymentMethod,
        paymentMethodComplete:paymentMethodComplete,
        businessTypeOptions:businessTypeOptions,
        setBusinessTypeSelection:setBusinessTypeSelection,
        businessTypeSelection:businessTypeSelection,
        businessTypeSelectionComplete:businessTypeSelectionComplete,
        setStateOfInc:setStateOfInc,
        stateOfInc:stateOfInc,
        stateOfIncComplete:stateOfIncComplete,
        //w9Options:w9Options,
        //setW9:setW9,
        //w9:w9,
        //w9Complete:w9Complete
    };


    const OwnersOrOfficersInformationProps = {
        setFirstName1:setFirstName1,
        firstName1:firstName1,
        setLastName1:setLastName1,
        lastName1:lastName1,
        ownerName1Complete:ownerName1Complete,
        setTitle:setTitle,
        title:title,
        titleComplete:titleComplete,
        setPhone:setPhone,
        phone:phone,
        phoneComplete:phoneComplete,
        setExtension:setExtension,
        extension:extension,
        setOwnerStreet:setOwnerStreet,
        ownerStreet:ownerStreet,
        ownerStreetComplete,ownerStreetComplete,
        setOwnerStreet2:setOwnerStreet2,
        ownerStreet2:ownerStreet2,
        setOwnerCity:setOwnerCity,
        ownerCity:ownerCity,
        ownerCityComplete:ownerCityComplete,
        setOwnerState:setOwnerState,
        ownerState:ownerState,
        ownerStateComplete:ownerStateComplete,
        setOwnerZip:setOwnerZip,
        ownerZip:ownerZip,
        ownerZipComplete:ownerZipComplete,
        setOwnerCountry:setOwnerCountry,
        ownerCountry:ownerCountry,
        setEmailAddress:setEmailAddress,
        emailAddress:emailAddress,
        emailAddressComplete:emailAddressComplete,
        setSecondaryOwner: setSecondaryOwner,
        secondaryOwner: secondaryOwner,
        setFirstName2:setFirstName2,
        firstName2:firstName2,
        setLastName2:setLastName2,
        lastName2Complete:lastName2,
        ownerName2Complete:ownerName2Complete,
        setTitle2:setTitle2,
        title2:title2,
        title2Complete:title2Complete,
        setPhone2:setPhone2,
        phone2:phone2,
        phone2Complete:phone2Complete,
        setExtension2:setExtension2,
        extension2:extension2,
        setOwner2Street:setOwner2Street,
        owner2Street:owner2Street,
        owner2StreetComplete,owner2StreetComplete,
        setOwner2Street2:setOwner2Street2,
        owner2Street2:owner2Street2,
        setOwner2City:setOwner2City,
        owner2City:owner2City,
        owner2CityComplete:owner2CityComplete,
        setOwner2State:setOwner2State,
        owner2State:owner2State,
        owner2StateComplete:owner2StateComplete,
        setOwner2Zip:setOwner2Zip,
        owner2Zip:owner2Zip,
        owner2ZipComplete:owner2ZipComplete,
        setOwner2Country:setOwner2Country,
        owner2Country:owner2Country,
        setEmailAddress2:setEmailAddress2,
        emailAddress2:emailAddress2,
        emailAddress2Complete:emailAddress2Complete,
    };

    const CreditCardInformationProps = {
        setNameOnCard:setNameOnCard,
        nameOnCard:nameOnCard,
        nameOnCardComplete:nameOnCardComplete,
        setCardNumber:setCardNumber,
        cardNumber:cardNumber,
        cardNumberComplete:cardNumberComplete,
        setExpDate:setExpDate,
        expDate:expDate,
        expDateComplete:expDateComplete,
        setCvv:setCvv,
        cvv:cvv,
        cvvComplete:cvvComplete,
        setCardZip:setCardZip,
        cardZip:cardZip,
        cardZipComplete:cardZipComplete,
    };

    const achProps = {
        achActive:achActive,
        setBankName:setBankName,
        bankName:bankName,
        bankNameComplete:bankNameComplete,
        setBankContactFirstName:setBankContactFirstName,
        bankContactFirstName:bankContactFirstName,
        setBankContactLastName:setBankContactLastName,
        bankContactLastName:bankContactLastName,
        bankContactNameComplete:bankContactNameComplete,
        setBankContactTitle:setBankContactTitle,
        bankContactTitle:bankContactTitle,
        bankContactTitleComplete:bankContactTitleComplete,
        setBankStreet:setBankStreet,
        bankStreet:bankStreet,
        setBankStreet2:setBankStreet2,
        bankStreet2:bankStreet2,
        bankStreetComplete:bankStreetComplete,
        setBankCity:setBankCity,
        bankCity:bankCity,
        bankCityComplete:bankCityComplete,
        setBankState:setBankState,
        bankState:bankState,
        bankStateComplete:bankStateComplete,
        setBankZip:setBankZip,
        bankZip:bankZip,
        bankZipComplete:bankZipComplete,
        setBankCountry:setBankCountry,
        bankCountry:bankCountry,
        bankCountryComplete:bankCountryComplete,
        setBankTelephone:setBankTelephone,
        bankTelephone:bankTelephone,
        bankTelephoneComplete:bankTelephoneComplete,
        setBankExtension:setBankExtension,
        bankExtension:bankExtension,
        setBankContactEmail:setBankContactEmail,
        bankContactEmail:bankContactEmail,
        bankContactEmailComplete:bankContactEmailComplete,
        setBankAccountName:setBankAccountName,
        bankAccountName:bankAccountName,
        bankAccountNameComplete:bankAccountNameComplete,
        setBankAccountNumber:setBankAccountNumber,
        bankAccountNumber:bankAccountNumber,
        bankAccountNumberComplete:bankAccountNumberComplete,
        setBankRoutingNumber:setBankRoutingNumber,
        bankRoutingNumber:bankRoutingNumber,
        bankRoutingNumberComplete:bankRoutingNumberComplete,
        setBankSwiftCode:setBankSwiftCode,
        bankSwiftCode:bankSwiftCode,
        bankSwiftCodeComplete:bankSwiftCodeComplete,
        setBankAccountSelection:setBankAccountSelection,
        bankAccountSelection:bankAccountSelection,
        bankAccountSelectionComplete:bankAccountSelectionComplete,
    };

  

    const TradeReferencesInformationProps = {
        setCompanyName:setCompanyName,
        companyName:companyName,
        companyNameComplete:companyNameComplete,
        setContactFirstName:setContactFirstName,
        contactFirstName:contactFirstName,
        setContactLastName:setContactLastName,
        contactLastName:contactLastName,
        contactTradeNameComplete:contactTradeNameComplete,
        setTradeTitle:setTradeTitle,
        tradeTitle:tradeTitle,
        tradeTitleComplete:tradeTitleComplete,
        setTradeEmail:setTradeEmail,
        tradeEmail:tradeEmail,
        tradeEmailComplete:tradeEmailComplete,
        setTradePhone:setTradePhone,
        tradePhone:tradePhone,
        tradePhoneComplete:tradePhoneComplete,
        setTradeExtension:setTradeExtension,
        tradeExtension:tradeExtension,
        setTradeStreet:setTradeStreet,
        tradeStreet:tradeStreet,
        setTradeStreet2:setTradeStreet2,
        tradeStreet2:tradeStreet2,
        tradeStreetComplete:tradeStreetComplete,
        setTradeCity:setTradeCity,
        tradeCity:tradeCity,
        tradeCityComplete:tradeCityComplete,
        setTradeState:setTradeState,
        tradeState:tradeState,
        tradeStateComplete:tradeStateComplete,
        setTradeZip:setTradeZip,
        tradeZip:tradeZip,
        tradeZipComplete:tradeZipComplete,
        setTradeCountry:setTradeCountry,
        tradeCountry:tradeCountry,
        tradeCountryComplete:tradeCountryComplete,
        setSecondaryReference:setSecondaryReference,
        secondaryReference:secondaryReference,
        setCompanyName2:setCompanyName2,
        companyName2:companyName2,
        companyName2Complete:companyName2Complete,
        setContactFirstName2:setContactFirstName2,
        contactFirstName2:contactFirstName2,
        setContactLastName2:setContactLastName2,
        contactLastName2:contactLastName2,
        contactTradeName2Complete:contactTradeName2Complete,
        setTradeTitle2:setTradeTitle2,
        tradeTitle2:tradeTitle2,
        tradeTitle2Complete:tradeTitle2Complete,
        setTradeEmail2:setTradeEmail2,
        tradeEmail2:tradeEmail2,
        tradeEmail2Complete:tradeEmail2Complete,
        setTradePhone2:setTradePhone2,
        tradePhone2:tradePhone2,
        tradePhone2Complete:tradePhone2Complete,
        setTradeExtension2:setTradeExtension2,
        tradeExtension2:tradeExtension2,
        setTrade2Street:setTrade2Street,
        trade2Street:trade2Street,
        setTrade2Street2:setTrade2Street2,
        trade2Street2:trade2Street2,
        trade2StreetComplete:trade2StreetComplete,
        setTrade2City:setTrade2City,
        trade2City:trade2City,
        trade2CityComplete:trade2CityComplete,
        setTrade2State:setTrade2State,
        trade2State:trade2State,
        trade2StateComplete:trade2StateComplete,
        setTrade2Zip:setTrade2Zip,
        trade2Zip:trade2Zip,
        trade2ZipComplete:trade2ZipComplete,
        setTrade2Country:setTrade2Country,
        trade2Country:trade2Country,
        trade2CountryComplete:trade2CountryComplete,
        setThirdReference:setThirdReference,
        thirdReference:thirdReference,
        setCompanyName3:setCompanyName3,
        companyName3:companyName3,
        companyName3Complete:companyName3Complete,
        setContactFirstName3:setContactFirstName3,
        contactFirstName3:contactFirstName2,
        setContactLastName3:setContactLastName3,
        contactLastName3:contactLastName3,
        contactTradeName3Complete:contactTradeName3Complete,
        setTradeTitle3:setTradeTitle3,
        tradeTitle3:tradeTitle3,
        tradeTitle3Complete:tradeTitle3Complete,
        setTradeEmail3:setTradeEmail3,
        tradeEmail3:tradeEmail3,
        tradeEmail3Complete:tradeEmail3Complete,
        setTradePhone3:setTradePhone3,
        tradePhone3:tradePhone3,
        tradePhone3Complete:tradePhone3Complete,
        setTradeExtension3:setTradeExtension3,
        tradeExtension3:tradeExtension3,
        setTrade3Street:setTrade3Street,
        trade3Street:trade3Street,
        setTrade3Street2:setTrade3Street2,
        trade3Street2:trade3Street2,
        trade3StreetComplete:trade3StreetComplete,
        setTrade3City:setTrade3City,
        trade3City:trade3City,
        trade3CityComplete:trade3CityComplete,
        setTrade3State:setTrade3State,
        trade3State:trade3State,
        trade3StateComplete:trade3StateComplete,
        setTrade3Zip:setTrade3Zip,
        trade3Zip:trade3Zip,
        trade3ZipComplete:trade3ZipComplete,
        setTrade3Country:setTrade3Country,
        trade3Country:trade3Country,
        trade3CountryComplete:trade3CountryComplete
    };

    const ConditionsAndAuthorizationsProps = {
        setConditionsComplete:setConditionsComplete,
        conditionsComplete:conditionsComplete,
        setNameSign:setNameSign,
        nameSign:nameSign,
        nameSignComplete:nameSignComplete,
        setTitleSign:setTitleSign,
        titleSign:titleSign,
        titleSignComplete:titleSignComplete,
        ///signature:signature,
        setSignature:setSignature,
        signatureComplete:signatureComplete,
        setDate:setDate,
        date:date,
        dateComplete:dateComplete,
        setSecondarySignature:setSecondarySignature,
        secondarySignature:secondarySignature,
        setNameSign2:setNameSign2,
        nameSign2:nameSign2,
        nameSign2Complete:nameSign2Complete,
        setTitleSign2:setTitleSign2,
        titleSign2:titleSign2,
        titleSign2Complete:titleSign2Complete,
        setSignature2:setSignature2,
        //signature2:signature2,
        signature2Complete:signature2Complete,
        setDate2:setDate2,
        date2:date2,
        date2Complete:date2Complete

        
    };    



    // CREATE JSON TO SEND TO BACK END
    const createCustomerRequestObject = () => {
        
        
        const customerObject = {    
            fedEinSSn:fedEinSSn,
            dunsNo:dunsNo,
            website:website,
            legalName:legalName,
            tradeNameDBA:tradeNameDBA,
            businessType: businessTypeSelection,

             address : {
                street:street,
                street2:street2,
                city:city,
                state:state,
                zip:zip,
                country:country,
            },

             shippingAddress : {
                street:shippingStreet,
                street2:shippingStreet2,
                city:shippingCity,
                state:shippingState,
                zip:shippingZip,
                country:shippingCountry,
            },

             primaryContact : {
                firstname:primaryContactFirstName,
                lastname:primaryContactLastName,
                number:primaryContactNumber,
                extension:primaryExtension,
                title:primaryTitle,
                address: {
                    street:primaryStreet,
                    street2:primaryStreet2,
                    city:primaryCity,
                    state:primaryState,
                    zip:primaryZip,
                    country:primaryCountry

                }
            },
            contactFirstNameAP:contactFirstNameAP,
            contactLastNameAP:contactLastNameAP,
            phoneAP:phoneAP,
            contactEmailAP:contactEmailAP,
            paymentMethod:paymentMethod,
            incState:stateOfInc,
             bankAccount : {
                name:bankName,
                accountname:bankAccountName,
                accountnumber:bankAccountNumber,
                routingNumber: bankRoutingNumber,
                swiftCode:bankSwiftCode,
                accountType:bankAccountSelection,
                address: {
                    street:bankStreet,
                    street2:bankStreet2,
                    city:bankCity,
                    State:bankState,
                    zip:bankZip,
                    country:bankCountry
                },
                Contact: {
                    firstName:bankContactFirstName,
                    lastName:bankContactLastName,
                    number:bankTelephone,
                    extension:bankExtension,
                    title:bankContactTitle,
                    email:bankContactEmail,
                }
            },

             authorizedOfficers : [
                {
                    firstname:firstName1,
                    lastname:lastName1,
                    phone:phone,
                    extension:extension,
                    title:title,
                    email:emailAddress,
                    address: {
                        street:ownerStreet,
                        street2:ownerStreet2,
                        city:ownerCity,
                        state:ownerState,
                        zip:ownerZip,
                        country:ownerCountry
                    }
                },
                {
                    firstname:firstName2,
                    lastname:lastName2,
                    phone:phone2,
                    extension:extension2,
                    title:title2,
                    email:emailAddress2,
                    address: {
                        street:owner2Street,
                        street2:owner2Street2,
                        city:owner2City,
                        state:ownerState,
                        zip:owner2Zip,
                        country:owner2Country
                    }
                },
        ],

         creditCard : {
            name:nameOnCard,
            number:cardNumber,
            expiration:expDate,
            billingzip:cardZip,
            securitycode:cvv,
        },

            
            tradeReferences : [
                {
                    firstName:contactFirstName,
                    lastName:contactLastName,
                    number:tradePhone,
                    extension:tradeExtension,
                    title:tradeTitle,
                    companyName:companyName,
                    emailAddress:tradeEmail,
                    Address: {
                        street:tradeStreet,
                        street2:tradeStreet2,
                        city:tradeCity,
                        state:tradeState,
                        zip:tradeZip,
                        country:tradeCountry,
    
                    }
                },
                {
                    firstName:contactFirstName2,
                    lastName:contactLastName2,
                    number:tradePhone2,
                    extension:tradeExtension2,
                    title:tradeTitle2,
                    companyName:companyName2,
                    emailAddress:tradeEmail2,
                    Address: {
                        street:trade2Street,
                        street2:trade2Street2,
                        city:trade2City,
                        state:trade2State,
                        zip:trade2Zip,
                        country:trade2Country,
    
                    }
                },
                {
                    firstName:contactFirstName3,
                    lastName:contactLastName3,
                    number:tradePhone3,
                    extension:tradeExtension3,
                    title:tradeTitle3,
                    companyName:companyName3,
                    emailAddress:tradeEmail3,
                    Address: {
                        street:trade3Street,
                        street2:trade3Street2,
                        city:trade3City,
                        state:trade3State,
                        zip:trade3Zip,
                        country:trade3Country,
    
                    }
                }
            ],
           
            
            signer1Name:nameSign,
            signer1Title:titleSign,
            signer2Name:nameSign2,
            signer2Title:titleSign2,

            w9file:w9Id,
            voidedcheckfile:voidedCheckFileId,
            intenationalwirefile:intWireId,
            taxExemption: taxCertId,
            creditReferenceSheet: creditReferenceSheetId,
        };

       

        return customerObject;
    };
    


    // WAITS FOR ALL FILES TO BE UPLOADED AND THEN CALLS TO SEND OBJECT TO BACK END
    useEffect(() => {
        let complete = true;
        console.log(createCustomerRequestObject())

        if(!checkFormFields()) complete = false;

        if(w9 && !w9Id) complete = false;
        if(taxCert && !taxCertId) complete = false;
        if(intWire && !intWireId) complete = false;
        if(voidedCheckFile && !voidedCheckFileId) complete = false;
        if(creditReferenceSheet && !creditReferenceSheetId) complete = false;

        if(complete) submitCustomerRequest(createCustomerRequestObject(),setFormSubmissionValid);
    },[]);


    const submitForm = () => {
        setSubmitting(true);

        //Submit w9
        sendW9(w9,`${tradeNameDBA}-W9-${getDate()}.pdf`,setW9Id,setW9Error);

        //Submit Voided Check (if Required)
        sendVoidedCheck(voidedCheckFile,`${tradeNameDBA}-Void Check-${getDate()}.pdf`,setVoidedCheckFileId,setVoidedCheckError)

        //Submit Internation Wire (if Required)
        sendInternationalWire(intWire,`${tradeNameDBA}-Int Wire-${getDate()}.pdf`,setIntWireId,setIntWireError)

        //Submit Tax Exemption (if Required)
        sendTaxCert(taxCert,`${tradeNameDBA}-Tax Cert-${getDate()}.pdf`,setTaxCertId,setTaxCertError)

        //Submit Credit Reference Sheet (if Required)
        sendCreditReferenceSheet(creditReferenceSheet,`${tradeNameDBA}-CreditReferenceSheet-${getDate()}.pdf`,setCreditReferenceSheetId,setCreditReferenceSheetError)
    };


    // RENDER NEW VENDOR PRIMARY
    return(
        <div className='External-Form-Window'>
            {   !submitting && !rejectionRecord &&
                <div className='External-Form-Page'>
                    <FormHeader formEmail={formEmail}/>

                    <CustomerInformation {...customerInformationProps} />

                    {   creditCardActive &&
                        <CreditCardInformation {...CreditCardInformationProps} />
                    }

                    {   achActive &&
                        <AchInformation {...achProps} />
                    }

                    <OwnersOrOfficersInformation {...OwnersOrOfficersInformationProps} />                

                    <TradeReferencesInformation {...TradeReferencesInformationProps} />

                    <RequiredFilesSection paymentMethod={paymentMethod} setW9={setW9} w9Active={w9Active} taxCertActive={taxCertActive} intWireActive={intWireActive} voidedCheckActive={voidedCheckActive} 
                    setFinishedW9={setFinishedW9} setTaxCert={setTaxCert} setIntWire={setIntWire} setVoidedCheckFile={setVoidedCheckFile} setCreditReferenceSheet={setCreditReferenceSheet}/>

                    <ConditionsAndAuthorizations {...ConditionsAndAuthorizationsProps} />

                    {   formComplete &&
                        <button onClick={() => submitForm()} className='External-Customer-Submit External-Customer-Button'>Submit Form</button>
                    }


                    <img src={'https://www.positivessl.com/images/seals/positivessl_trust_seal_md_167x42.png'} className='External-Vendor-SSL'/>

                </div>
            }
            {   submitting && !rejectionRecord &&
                <div>
                    <FormSubmissionWindow taxCertId={taxCertId} voidedCheckFileId={voidedCheckFileId} voidedCheckActive={voidedCheckActive} 
                    w9Id={w9Id} intWireActive={intWireActive} intWireId={intWireId} finalizedSubmitting={finalizedSubmitting}/>
                </div>
            }

            {   rejectionRecord &&
                <div>

                </div>
            }
            
        </div>
    )
};

export default NewCustomer;


/*
{
    "CustomerObject" : {
        "fedEinSsn" : "String",
        "dunsNo" : "String",
        "website" : "String",
        "legalName" : "String",
        "tradeName" : "String",
        "address" : {
            "street": "String",
            "street2": "String",
            "city": "String",
            "state": "String",
            "zip": "String",
            "country": "String"
        },
        "shippingAddress" : {
            "street": "String",
            "street2": "String",
            "city": "String",
            "state": "String",
            "zip": "String",
            "country": "String"
        },
        "primaryContact" : {
            "firstName": "String",
            "lastName": "String",
            "number": "String",
            "extension": "String",
            "title": "String",
            "address" : {
                "street": "String",
                "street2": "String",
                "city": "String",
                "state": "String",
                "zip": "String",
                "country": "String"
            }
        },
        "phoneAP" : "String",
        "emailAP" : "String",
        "paymentMethod" : "String",
        "incState" : "String",
        "bankAccount" : {
            "name" : "String",
            "contact" : {
                "firstName": "String",
                "lastName": "String",
                "number": "String",
                "extension": "String",
                "title": "String",
                "address" : {
                    "street": "String",
                    "street2": "String",
                    "city": "String",
                    "state": "String",
                    "zip": "String",
                    "country": "String"
                }
            },
            "address" : {
                "street": "String",
                "street2": "String",
                "city": "String",
                "state": "String",
                "zip": "String",
                "country": "String"
            },
            "accountName": "String",
            "accountNumber": "String",
            "swiftCode": "String",
            "accountType": "String"
        },
        "authorizedOfficers" : [
            {
                "firstName": "String",
                "lastName": "String",
                "number": "String",
                "extension": "String",
                "title": "String",
                "address" : {
                    "street": "String",
                    "street2": "String",
                    "city": "String",
                    "state": "String",
                    "zip": "String",
                    "country": "String"
                }
            }
        ],
        "creditCard" : {
            "name" : "String",
            "number" : "String",
            "expiration" : "Date",
            "billingZip" : "String",
            "securityCode" : "String"
        },
        "tradeReferences" : [
            {
                "firstName": "String",
                "lastName": "String",
                "number": "String",
                "extension": "String",
                "title": "String",
                "address" : {
                    "street": "String",
                    "street2": "String",
                    "city": "String",
                    "state": "String",
                    "zip": "String",
                    "country": "String"
                }
            }
        ],
        "signer1Name" : "String",
        "signer1Title" : "String",
        "signer2Name" : "String",
        "signer2Title" : "String",
        
        "w9File" : "Integer",
        "voidedCheckFile" : "Integer",
        "internationalWireFile" : "Integer",
        "creditCardFile" : "Integer",
        "creditReferencesSheetFile" : "Integer"
    }
}

// - Printed Out During Test - 

{
    "fedEinSSn": "231523512",
    "dunsNo": "235235235",
    "website": "www.kysondesigns.com",
    "legalName": "Kyson Designs, Inc.",
    "tradeNameDBA": "Kyson Designs",
    "businessType": "Corporation",
    "address": {
        "street": "123 West Life",
        "street2": "Suite 205",
        "city": "Durham",
        "state": "NC",
        "zip": "27703",
        "country": "US"
    },
    "shippingAddress": {
        "street": "123 West Price",
        "street2": "Suite 205",
        "city": "Durham",
        "state": "NC",
        "zip": "27703",
        "country": "US"
    },
    "primaryContact": {
        "firstname": "Kyle",
        "lastname": "Bostelman",
        "number": "4194392829",
        "extension": "102",
        "title": "President",
        "address": {
            "street": "123 West Main",
            "street2": "Suite 204",
            "city": "Durham",
            "state": "NC",
            "zip": "27703",
            "country": "US"
        }
    },
    "contactFirstNameAP": "Kyle",
    "contactLastNameAP": "Bostelman",
    "phoneAP": "4194392829",
    "contactEmailAP": "bostelman@live.com",
    "paymentMethod": "ACH",
    "incState": "NC",
    "bankAccount": {
        "name": "Fifth Third Bank",
        "accountname": "Kyson Designs",
        "accountnumber": "712412515215",
        "routingNumber": "232531532",
        "swiftCode": "22352352352",
        "accountType": "Checking",
        "address": {
            "street": "123 West Main",
            "street2": "Suite 203",
            "city": "Durham",
            "State": "NC",
            "zip": "27703",
            "country": "US"
        },
        "Contact": {
            "firstName": "David",
            "lastName": "Williams",
            "number": "9192131241",
            "extension": "105",
            "title": "Teller",
            "email": "dwilliams@53.com"
        }
    },
    "authorizedOfficers": [
        {
            "firstname": "Kyle",
            "lastname": "Bostelman",
            "phone": "9235192512",
            "extension": "15",
            "title": "Somebody",
            "email": "kbostelman@123.com",
            "address": {
                "street": "123 South Main",
                "street2": "Suite 909",
                "city": "Durham",
                "state": "NC",
                "zip": "27703",
                "country": "US"
            }
        },
        {
            "firstname": "Natalie",
            "lastname": "Bostelman",
            "phone": "9151241295",
            "extension": "13",
            "title": "somebody else",
            "email": "nbostelman@123.com",
            "address": {
                "street": "143 Rice St",
                "street2": "Apt 12",
                "city": "Durham",
                "state": "NC",
                "zip": "27703",
                "country": "US"
            }
        }
    ],
    "creditCard": {
        "name": "",
        "number": "",
        "expiration": "",
        "billingzip": "",
        "securitycode": ""
    },
    "tradeReferences": [
        {
            "firstName": "Josh",
            "lastName": "Smith",
            "number": "9191351241",
            "extension": "12",
            "title": "owner",
            "companyName": "Alpine Electric",
            "emailAddress": "jsmith@apline.com",
            "Address": {
                "street": "456 South Main",
                "street2": "Suite 6",
                "city": "San Antonio",
                "state": "TX",
                "zip": "64265",
                "country": "US"
            }
        },
        {
            "firstName": "lisa",
            "lastName": "Barber",
            "number": "1531351351",
            "extension": "90",
            "title": "president",
            "companyName": "Test Company",
            "emailAddress": "lbarber@something.com",
            "Address": {
                "street": "365 West Manchester",
                "street2": "Suite 14",
                "city": "Denver",
                "state": "CO",
                "zip": "23452",
                "country": "US"
            }
        },
        {
            "firstName": "Richard",
            "lastName": "Davis",
            "number": "4194392829",
            "extension": "14",
            "title": "Owner",
            "companyName": "Another Test Company",
            "emailAddress": "rdavid@test.com",
            "Address": {
                "street": "413 South Davis Rd.",
                "street2": "Suite 5",
                "city": "Montgomery",
                "state": "AL",
                "zip": "15325",
                "country": "US"
            }
        }
    ],
    "signer1Name": "Kyle Bostelman",
    "signer1Title": "President",
    "signer2Name": "",
    "signer2Title": ""
}
*/