import './PrimaryWindow.css';
import VendorRequests from '../VendorRequests/VendorRequests';
import AccountSettings from '../AccountSettings/AccountSettings';
import Logout from '../AccountSettings/Logout';
import UsersWindow from '../Users/UsersWindow';
import ProjectBooking from '../ProjectBooking/ProjectBooking';
import ProjectBookingReview from '../ProjectBooking/ProjectBookingReview';
import FixedAssets from '../FixedAssets/FixedAssets';
import FixedAssetsReview from '../FixedAssets/FixedAssetsReview';
import CustomerRequests from '../Customers/CustomerReview';
import Conversion from '../Conversion/Conversion';

import { useContext } from 'react';
import {Context} from '../Context';


const PrimaryWindow = () => {
    const contextObject = useContext(Context);

    return(
        <>
        {   contextObject.selectedNav === 'Vendor Requests' &&
            <VendorRequests />
        }
        {   contextObject.selectedNav === 'Customer Requests' &&
            <CustomerRequests />
        }
        {   contextObject.selectedNav === 'New Project Booking' &&
            <ProjectBooking />
        }
        {   contextObject.selectedNav === 'Project Booking Requests' &&
            <ProjectBookingReview />
        }
        {   contextObject.selectedNav === 'New Fixed Asset' &&
            <FixedAssets />
        }
        {   contextObject.selectedNav === 'Fixed Asset Requests' &&
            <FixedAssetsReview />
        }
        {   contextObject.selectedNav === 'Conversion System' &&
            <Conversion />
        }
        {   contextObject.selectedNav === 'Manage Users' &&
            <UsersWindow />
        }
        {   contextObject.selectedNav === 'Account Settings' &&
            <AccountSettings activeUser={contextObject.activeUser}/>
        }
        {   contextObject.selectedNav === 'Logout' &&
            <Logout />
        }
        </>
    )
};

export default PrimaryWindow;