import './AccountSettings.css';
import { Context } from '../Context';
import { useEffect, useState, useContext } from 'react';

import { ImCheckboxUnchecked, ImCheckboxChecked  } from "react-icons/im";

const AccountSettings = ({activeUser}) => {
    const contextObject = useContext(Context);

    const [ changingPassword, setChangingPassword ] = useState(false);
    const [ currentPasswordInput, setCurrentPasswordInput ] = useState('');
    const [ newPassword1, setNewPassword1 ] = useState('');
    const [ newPassword2, setNewPassword2 ] = useState('');

    const [ clickAble, setClickAble ] = useState(false);
    const [ noMatchWarning, setNoMatchWarning ] = useState(false);
    const [ wrongPasswordWarning, setWrongPasswordWarning ] = useState(false);
    const [ successfulPasswordChange, setSuccessfulPasswordChange ] = useState(false);

    useEffect(() => {
        if(newPassword2 !== ''){
            if(newPassword1 !== newPassword2){
                setNoMatchWarning(true);
                setClickAble(false);
            }
            else {
                setNoMatchWarning(false);
                setClickAble(true);
            }
        }
    },[newPassword2]); 

    const successfulChange = () => {
        setSuccessfulPasswordChange(true);
        setTimeout(() => {
            contextObject.logoutUser()
        },2000)
    };

    const failedChange = (text) => {
        console.log(text);
        setWrongPasswordWarning(true);
    };

    const updatePassword = () => {
        contextObject.changePassword(currentPasswordInput, newPassword1, successfulChange, failedChange)
    };

    return(
        <div className='Account-Settings-Window'>

            <div className='Account-Settings-Section'>

                <div className='Account-Settings-Title'>
                    Name
                </div>
                <div className='Account-Settings-Value'>
                    {activeUser.name}
                </div>

                <div className='Account-Settings-Title'>
                    Email Address
                </div>
                <div className='Account-Settings-Value'>
                    {activeUser.email}
                </div>

            </div>

            <div className='Account-Settings-Section'>
                <div className='Account-Settings-Title'>
                    Privileges
                </div>

                <div className='Account-Settings-Priv-Group'>
                    {   activeUser.vendorPriv &&
                        <ImCheckboxChecked className='Account-Settings-Priv-Icon Account-Settings-Priv-Selected'/>
                    }
                    {   !activeUser.vendorPriv &&
                        <ImCheckboxUnchecked className='Account-Settings-Priv-Icon'/>
                    }
                    <div className='Account-Settings-Priv-Title'>
                        Vendor Requests
                    </div>
                </div>

                <div className='Account-Settings-Priv-Group'>
                    {   activeUser.customerPriv &&
                        <ImCheckboxChecked className='Account-Settings-Priv-Icon Account-Settings-Priv-Selected'/>
                    }
                    {   !activeUser.customerPriv &&
                        <ImCheckboxUnchecked className='Account-Settings-Priv-Icon'/>
                    }
                    <div className='Account-Settings-Priv-Title'>
                        Customer Requests
                    </div>
                </div>
                
                <div className='Account-Settings-Priv-Group'>
                    {   activeUser.projectBookingPriv &&
                        <ImCheckboxChecked className='Account-Settings-Priv-Icon Account-Settings-Priv-Selected'/>
                    }
                    {   !activeUser.projectBookingPriv &&
                        <ImCheckboxUnchecked className='Account-Settings-Priv-Icon'/>
                    }
                    <div className='Account-Settings-Priv-Title'>
                        Project Booking
                    </div>
                </div>

                <div className='Account-Settings-Priv-Group'>
                    {   activeUser.projectBookingReviewPriv &&
                        <ImCheckboxChecked className='Account-Settings-Priv-Icon Account-Settings-Priv-Selected'/>
                    }
                    {   !activeUser.projectBookingReviewPriv &&
                        <ImCheckboxUnchecked className='Account-Settings-Priv-Icon'/>
                    }
                    <div className='Account-Settings-Priv-Title'>
                        Project Booking Review
                    </div>
                </div>

                <div className='Account-Settings-Priv-Group'>
                    {   activeUser.convertPriv &&
                        <ImCheckboxChecked className='Account-Settings-Priv-Icon Account-Settings-Priv-Selected'/>
                    }
                    {   !activeUser.convertPriv &&
                        <ImCheckboxUnchecked className='Account-Settings-Priv-Icon'/>
                    }
                    <div className='Account-Settings-Priv-Title'>
                        Conversion System
                    </div>
                </div>

                <div className='Account-Settings-Priv-Group'>
                    {   activeUser.adminPriv &&
                        <ImCheckboxChecked className='Account-Settings-Priv-Icon Account-Settings-Priv-Selected'/>
                    }
                    {   !activeUser.adminPriv &&
                        <ImCheckboxUnchecked className='Account-Settings-Priv-Icon'/>
                    }
                    <div className='Account-Settings-Priv-Title'>
                        System Admin
                    </div>
                </div>


                

            </div>

            <div className='Account-Settings-Section'>

                {   !changingPassword &&
                <>
                <div className='Account-Settings-Title'>
                    Password
                </div>

                <div className='Account-Settings-Update-Password' onClick={() => setChangingPassword(true)}>
                    Update your password
                </div>
                </>

                }

                {   changingPassword &&
                <div className='Account-Settings-Password-Change'>
                    <div className='Account-Settings-Title'>
                        Current Password
                    </div>
                    <input type={'password'} onChange={(e) => setCurrentPasswordInput(e.target.value) }/>

                    <div className='Account-Settings-Title'>
                        New Password
                    </div>
                    <input type={'password'} onChange={(e) => setNewPassword1(e.target.value) }/>

                    <div className='Account-Settings-Title'>
                        Confirm Password
                    </div>
                    <input type={'password'} onChange={(e) => setNewPassword2(e.target.value) }/>

                    {   noMatchWarning &&
                        <div className='Account-Settings-Warning'>
                            Passwords do not match.
                        </div>
                    }

                    {   wrongPasswordWarning &&
                        <div className='Account-Settings-Warning'>
                            Current password is not correct.
                        </div>
                    }

                    {   successfulPasswordChange &&
                        <div className='Account-Settings-Success'>
                            Password has been updated.
                        </div>
                    }

                    {   clickAble &&
                    <div className='Account-Settings-Confirm Account-Settings-Confirm-Clickable' onClick={() => updatePassword()}>
                        Update Password
                    </div>
                    }

                    {   !clickAble &&
                    <div className='Account-Settings-Confirm'>
                        Update Password
                    </div>
                    }

                    <div className='Account-Settings-Confirm Account-Settings-Cancel' onClick={() => setChangingPassword(false)}>
                        Cancel
                    </div>

                </div>
                }


            </div>

        </div>
    )
};

export default AccountSettings;