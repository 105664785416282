import axios from "axios";
import { API_URL } from '../Constants';


class newCustomerDataService{
    submitVoidedCheck(voidedCheck, filename) {
        const formData = new FormData();
        formData.append("file", voidedCheck);
        formData.append('fileName', filename);
        return axios.post(`${API_URL}postVoidedCheck/`, formData, {headers:{'content-type': 'multipart/form-data'}});
    };

    getW9() {
        window.open(`${API_URL}getCustomerW9/`)
    };

    submitW9(w9, filename) {
        const formData = new FormData();
        formData.append("file", w9);
        formData.append('fileName', filename);
        return axios.post(`${API_URL}postCustomerW9/`, formData, {headers:{'content-type': 'multipart/form-data'}});
    };

    submitIntWire(intWire, filename) {
        const formData = new FormData();
        formData.append("file", intWire);
        formData.append('fileName', filename);
        return axios.post(`${API_URL}postCustomerIntWire/`, formData, {headers:{'content-type': 'multipart/form-data'}});
    };

    submitCreditReferencesSheet(creditReferenceSheet, filename) {
        const formData = new FormData();
        formData.append("file", creditReferenceSheet);
        formData.append('fileName', filename);
        return axios.post(`${API_URL}postCustomerCreditSheet/`, formData, {headers:{'content-type': 'multipart/form-data'}});
    };    

    submitTaxExemption(taxExemption, filename) {
        const formData = new FormData();
        formData.append("file", taxExemption);
        formData.append('fileName', filename);
        return axios.post(`${API_URL}postCustomerTaxExemption/`, formData, {headers:{'content-type': 'multipart/form-data'}});
    }

    submitCustomerRecord(CustomerObject) {
        console.log(CustomerObject)
        return axios.post(`${API_URL}postNewCustomerRequest/`, CustomerObject);
    };

    updateCustomerRecord(CustomerObject) {
        return axios.post(`${API_URL}updateNewCustomerRequest/`, CustomerObject);
    };

    getCustomerRejectionRecord(CustomerObject) {
        return axios.post(`${API_URL}updateNewCustomerRequest/`, CustomerObject);
    };
};

newCustomerDataService = new newCustomerDataService()
export default newCustomerDataService;