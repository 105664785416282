import axios from "axios";
import { API_URL } from '../Constants';

const convertToBase64 = (arrayBuffer) => {
    var base64    = ''
    var encodings = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/'
    
    var bytes         = new Uint8Array(arrayBuffer)
    var byteLength    = bytes.byteLength
    var byteRemainder = byteLength % 3
    var mainLength    = byteLength - byteRemainder
    
    var a, b, c, d
    var chunk
    
    // Main loop deals with bytes in chunks of 3
    for (var i = 0; i < mainLength; i = i + 3) {
        // Combine the three bytes into a single integer
        chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2]
    
        // Use bitmasks to extract 6-bit segments from the triplet
        a = (chunk & 16515072) >> 18 // 16515072 = (2^6 - 1) << 18
        b = (chunk & 258048)   >> 12 // 258048   = (2^6 - 1) << 12
        c = (chunk & 4032)     >>  6 // 4032     = (2^6 - 1) << 6
        d = chunk & 63               // 63       = 2^6 - 1
    
        // Convert the raw binary segments to the appropriate ASCII encoding
        base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d]
    }
    
    // Deal with the remaining bytes and padding
    if (byteRemainder == 1) {
        chunk = bytes[mainLength]
    
        a = (chunk & 252) >> 2 // 252 = (2^6 - 1) << 2
    
        // Set the 4 least significant bits to zero
        b = (chunk & 3)   << 4 // 3   = 2^2 - 1
    
        base64 += encodings[a] + encodings[b] + '=='
    } else if (byteRemainder == 2) {
        chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1]
    
        a = (chunk & 64512) >> 10 // 64512 = (2^6 - 1) << 10
        b = (chunk & 1008)  >>  4 // 1008  = (2^6 - 1) << 4
    
        // Set the 2 least significant bits to zero
        c = (chunk & 15)    <<  2 // 15    = 2^4 - 1
    
        base64 += encodings[a] + encodings[b] + encodings[c] + '='
    }
    
    return base64
};

class newCustomerRequestDataService{
    // PUBLIC - ONLY VALID ACCESS TOKEN REQUIRED
    async getRejectionRecord(sessionId, rejectionId) {
        return axios.get(`${API_URL}getRejectionRecord/`, {params:{sessionId:sessionId, id:rejectionId}});
    };

    // PRIVATE - PASSWORD PROTECTED
    submitRejectionRecord(accessToken, rejection) {
        return axios.post(`${API_URL}postCustomerRejectionRecord/`, rejection, {headers:{'Authorization':accessToken}});
    };

    getCustomerList(accessToken, filterObject) {
        return axios.get(`${API_URL}getCustomerList/`,  {headers:{'Authorization':accessToken}, params:filterObject},);
    };

    getSearchList(accessToken, searchObject) {
        return axios.get(`${API_URL}getCustomerSearchList/`,  {headers:{'Authorization':accessToken}, params:searchObject},);
    };

    toggleCustomerFlag(accessToken, id) {
        const idObject = {
            id:id,
        }
        return axios.put(`${API_URL}toggleCustomerFlag/`,idObject, {headers:{'Authorization':accessToken}});
    };

    getCustomerRecord(accessToken, id) {
        return axios.get(`${API_URL}getCustomerRecord/${id}`,  {headers:{'Authorization':accessToken}});
    };

    inactivateRecord(accessToken, id) {
        return axios.put(`${API_URL}activateCustomerRecord/${id}`,{id:id}, {headers:{'Authorization':accessToken}});
    };

    activateRecord(accessToken, id) {
        return axios.put(`${API_URL}activateCustomerRecord/${id}`,{id:id}, {headers:{'Authorization':accessToken}});
    };

    async getW9(accessToken, id){
        return axios.get(`${API_URL}getCustomerW9/${id}`, {responseType: "arraybuffer", headers:{'Authorization':accessToken}}).then((res) => {
            return(convertToBase64(res.data));
        });
    };

    async getRefSheet(accessToken, id){
        return axios.get(`${API_URL}getCustomerRefSheet/${id}`, {responseType: "arraybuffer", headers:{'Authorization':accessToken}}).then((res) => {
            return(convertToBase64(res.data));
        });
    };

    async getVoidedCheck(accessToken, id){
        return axios.get(`${API_URL}getCustomerVoidedCheck/${id}`, {responseType: "arraybuffer", headers:{'Authorization':accessToken}}).then((res) => {
            return(convertToBase64(res.data));
        });
    };
    
    async getInternationalWire(accessToken, id){
        return axios.get(`${API_URL}getCustomerInternationalWire/${id}`, {responseType: "arraybuffer", headers:{'Authorization':accessToken}}).then((res) => {
            return(convertToBase64(res.data));
        });
    };

    async rejectRecord(accessToken, rejection){
        return axios.post(`${API_URL}postCustomerRejectionRecord/`, rejection, {headers:{'Authorization':accessToken}});
    };

    async editRecord(accessToken, updatedRecord){
        return axios.put(`${API_URL}editCustomerRecord/`, updatedRecord, {headers:{'Authorization':accessToken}});
    };

    async approveRecord(accessToken, approvalObject){
        return axios.post(`${API_URL}approveCustomerRecord/`, approvalObject, {headers:{'Authorization':accessToken}});
    };
};

newCustomerRequestDataService = new newCustomerRequestDataService()
export default newCustomerRequestDataService;